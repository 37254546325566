
export const userNavigation = [
    {
        text: 'Dashboard',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Strategies',
        path: '/auto-trade',
        icon: 'money'
    }
];

export const memberNavigation = [
    {
        text: 'Dashboard',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Strategies',
        path: '/auto-trade',
        icon: 'money'
    }/*,
    {
        text: 'Intraday',
        path: '/intraday-study',
        icon: 'variable'
    },
    {
        text: 'Swing',
        path: '/swing-study',
        icon: 'indent'
    }*/
];

export const adminNavigation = [
    {
        text: 'Dashboard',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Strategies',
        path: '/auto-trade',
        icon: 'money'
    },
    /*{
        text: 'Intraday',
        path: '/intraday-study',
        icon: 'variable'
    },
    {
        text: 'Swing',
        path: '/swing-study',
        icon: 'indent'
    },*/
    {
        text: 'Admin Console',
        path: '/user/admin',
        icon: 'preferences'
    },
    {
        text: 'Analysis',
        path: '/analysis',
        icon: 'chart'
    }
];
