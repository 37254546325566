import axios from "axios";
import {API_BASE_URL} from "../../constants/UrlServiceConstants";
import {apiHeader} from "./headers/ApiHeaders";

const getKiteApiInfo = () => {
    return axios.get(`${API_BASE_URL}/zerodha/api-info`, {headers: {Authorization: apiHeader()}})
};

const saveKiteApiInfo = (data) => {
    return axios.put(`${API_BASE_URL}/zerodha/api-info`, data, {headers: {Authorization: apiHeader()}})
}
export default {getKiteApiInfo, saveKiteApiInfo}