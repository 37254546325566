import axios from "axios";
import {API_BASE_URL} from "../../../constants/UrlServiceConstants";
import {apiHeader} from "../headers/ApiHeaders";

const fetchOHLCByName = (name) => {
    return axios.get(`${API_BASE_URL}/admin/ohlc?name=${name}`,
        {headers: {Authorization: apiHeader()}})
};

const deleteOHLC = (id) => {
    return axios.delete(`${API_BASE_URL}/admin/ohlc?id=${id}`,
        {headers: {Authorization: apiHeader()}})
};

function saveNewOHLC(bodyData) {
    return axios.post(`${API_BASE_URL}/admin/ohlc`,
        bodyData,
        {headers: {Authorization: apiHeader()}})
};

export default {fetchOHLCByName, saveNewOHLC, deleteOHLC};
