import React, {useEffect, useState} from 'react';
import "./study.scss"
import {useAuth} from "../../contexts/auth";
import TredCodeService from "../../api/service/TredCodeService";
import moment from 'moment';
import AutoTradeHelper from "../auto-trade/helper/AutoTradeHelper";
import {Button} from "devextreme-react/button";
import { CheckBox } from 'devextreme-react/check-box';

const IntradayStudyMain = () => {
    const {user} = useAuth();
    const [loading, setLoading] = useState(true);
    const [min5MomentumStocks, setMin5MomentumStocks] = useState([])
    const [min10MomentumStocks, setMin10MomentumStocks] = useState([])
    const [isMomentumStocksLoading, setIsMomentumStocksLoading] = useState(true);
    const maxRiskPriceRange = AutoTradeHelper.maxRiskPrice;

    const [intradayBoostStocks, setIntradayBoostStocks] = useState([])
    const [highPowerStocks, setHighPowerStocks] = useState([])
    const [niftyPremarketMovers, setNiftyPremarketMovers] = useState([])
    const [fnoPremarketMovers, setFnoPremarketMovers] = useState([])
    const [sectors, setSectors] = useState([])
    const [sectorStocks, setSectorStocks] = useState([])
    const [selectedSector, setSelectedSector] = useState("")
    const [stockToHighlight, setStockToHighlight] = useState("")
    const [selectedSectorStocks, setSelectedSectorStocks] = useState([])
    const [timeframe, setTimeframe] = useState([5])
    const [direction, setDirection] = useState("ALL")

    const [selectedSymbolDetail, setSelectedSymbolDetail] = useState([]);
    const [maxRiskPrice, setMaxRiskPrice] = useState(1000);
    const [transactionType, setTransactionType] = useState("BUY")
    const [tradeEntryPrice, setTradeEntryPrice] = useState(0);
    const [tradeStopLoss, setTradeStopLoss] = useState(0);
    const [tradeTarget, setTradeTarget] = useState(0);
    const [tradeQuantity, setTradeQuantity] = useState(0);

    const [shortlistedStocks, setShortlistedStocks] = useState([]);
    const [skippedStocks, setSkippedStocks] = useState([]);
    const [hideSkipped, setHideSkipped] = useState(false);

    const autoRefreshMs = 5000; // 5 secs
    let interval;
    useEffect(() => {
        loadMomentumStocks();
        loadIntradayBoostStocks();
        loadHighPowerStocks();
        loadSectorialView();
        loadPremarketMovers();
        loadSelectedStockDetail(stockToHighlight)

        loadShortlistedStocks();
        loadSkippedStocks();
        interval = setInterval(() => {
            loadMomentumStocks();
            loadIntradayBoostStocks();
            loadHighPowerStocks();
            loadPremarketMovers();
            loadSectorialView();
            loadSelectedStockDetail(stockToHighlight)
        }, autoRefreshMs);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        calculateTradeQuantity();
    }, [tradeEntryPrice, tradeStopLoss, transactionType]);


    function loadSelectedStockDetail(stockName, tf) {
        if (stockName === null || stockName === "") {
            if (stockToHighlight != null) stockName = stockToHighlight;
        }
        if (stockName === null || stockName === "") return;
        if (tf == null) tf = timeframe;

        console.log("Loading detail for: " + stockName + " with TF: " + tf);
        TredCodeService.getIntradayStockDetail(stockName, tf)
            .then(response => {
                if (response.status === 200) {
                    setSelectedSymbolDetail(response.data);

                    // TODO: auto-populate entry price with LTP
                    // TODO: load SL set for this symbol from local-storage
                }
            }).catch(error => {
            console.error(error)
        });
    }

    function loadMomentumStocks() {
        setIsMomentumStocksLoading(true);
        TredCodeService.getMomentumStocks()
            .then(response => {
                if (response.status === 200) {
                    setMin5MomentumStocks(response.data.min5);
                    setMin10MomentumStocks(response.data.min10);
                    setIsMomentumStocksLoading(false);
                }
            }).catch(error => {
            console.error(error)
        });
    }

    function loadIntradayBoostStocks() {
        TredCodeService.getIntradayBoostStocks()
            .then(response => {
                if (response.status === 200) {
                    setIntradayBoostStocks(response.data.stocks);
                }
            }).catch(error => {
            console.error(error)
        });
    }

    function loadHighPowerStocks() {
        TredCodeService.getHighPowerStocks()
            .then(response => {
                if (response.status === 200) {
                    setHighPowerStocks(response.data.stocks);
                }
            }).catch(error => {
            console.error(error)
        });
    }

    function loadPremarketMovers() {
        TredCodeService.getPremarketMovers()
            .then(response => {
                if (response.status === 200) {
                    setNiftyPremarketMovers(response.data.nifty);
                    setFnoPremarketMovers(response.data.fno);
                }
            }).catch(error => {
            console.error(error)
        });
    }

    function loadSectorialView() {
        TredCodeService.getSectorialView()
            .then(response => {
                if (response.status === 200) {
                    setSectors(response.data.sectors);
                    setSectorStocks(response.data.sectorStocks);
                }
            }).catch(error => {
            console.error(error)
        });
    }

    function changeSelectedSector(sectorName) {
        console.log("changing selected sector to " + sectorName);
        setSelectedSector(sectorName);
        setSelectedSectorStocks(sectorStocks[sectorName])
    }

    function changeStockToHighlight(stockName) {
        console.log("changing stock to highlight: " + stockName);
        setStockToHighlight(stockName);
        loadSelectedStockDetail(stockName);
    }

    function getHighlightStyleClass(stockName) {
        if (stockName === stockToHighlight) return " highlighted-stock";
        return "";
    }

    function getHighlightStyleClassForSector(sectorName) {
        if (sectorName === selectedSector) return " highlighted-sector";
        return "";
    }

    function getGreenOrRedColor(value) {
        // if value is negative, return red styled class,
        // if value is positive, return green styled class
        if (value >= 0) return " change-positive";
        else return " change-negative"
    }

    function getRankChangeIcon(item) {
        if (item.prevRank == null) return "dx-icon-minus";
        const diff = item.prevRank - item.rank;
        if (diff === 0) return "dx-icon-equal";
        if (diff > 0) return "change-positive dx-icon-arrowup";
        return "change-negative dx-icon-arrowdown"
    }

    function changeTimeframe(e) {
        setTimeframe(e.target.value);
        setTimeout(() => loadSelectedStockDetail(stockToHighlight, e.target.value), 1);
    }

    function changeDirectionFilter(e) {
        setDirection(e.target.value);
        console.log("direction filter changed to: " + direction);
        setTimeout(() => setDirection(e.target.value), 3);
        return true;
    }

    function isPerDirectionFilter(stock) {
        if (direction === "ALL") return true;
        if (direction === "BULLISH") return stock.percentageChange > 0
        return stock.percentageChange < 0;
    }

    function initiateTrade() {
        console.log("initiating new trade ... ")
    }

    function calculateLoss() {
        if (tradeEntryPrice == null || tradeStopLoss == null) return 0;
        let risk = tradeEntryPrice - tradeStopLoss
        if (transactionType === 'SELL') {
            risk = tradeStopLoss - tradeEntryPrice
        }
        risk = (Math.round(risk * 100) / 100).toFixed(1);
        return risk;
    }

    function calculateTarget(ratio) {
        if (tradeEntryPrice == null || tradeStopLoss == null) return 0;
        const risk = calculateLoss();
        let target = Number(tradeEntryPrice) + (risk * ratio);
        if (transactionType === 'SELL') {
            target = Number(tradeEntryPrice) - (risk * ratio);
        }
        target = (Math.round(target * 100) / 100).toFixed(1);
        return target;
    }

    function calculateTradeQuantity() {
        if (tradeEntryPrice == null || tradeStopLoss == null) {
            setTradeQuantity(0);
        }
        console.log("Re-calculating Qty to trade based on entry/SL & risk")
        console.log("Transaction type: " + transactionType)

        let risk = tradeEntryPrice - tradeStopLoss
        if (transactionType === 'SELL') {
            risk = tradeStopLoss - tradeEntryPrice
        }
        console.log('risk ' + risk)
        const maxQty = maxRiskPrice / risk;
        if (maxQty === Number.POSITIVE_INFINITY || maxQty === Number.NEGATIVE_INFINITY) {
            setTradeQuantity(0);
            return;
        }
        setTradeQuantity(Math.floor(maxQty))
    }

    function skipStockForToday(symbol) {
        var stored = loadSkippedStocks();
        if (stored.indexOf(symbol) === -1) {
            stored.push(symbol);
        }
        localStorage.setItem("skipped-stocks", JSON.stringify(stored));
        setSkippedStocks(stored);
        // TODO: if skipped - remove from shortlisted & vice-versa
    }

    function shortlistStock(symbol) {
        var stored = loadShortlistedStocks();
        if (stored.indexOf(symbol) === -1) {
            stored.push(symbol);
        }
        localStorage.setItem("shortlisted-stocks", JSON.stringify(stored));
        setShortlistedStocks(stored);
    }

    /** loads shortlisted stock names from local-storage **/
    function loadShortlistedStocks() {
        var stored = JSON.parse(localStorage.getItem("shortlisted-stocks"));
        if (stored == null) stored = [];
        setShortlistedStocks(stored);
        return stored;
    }

    /** loads skipped stock names from local-storage **/
    function loadSkippedStocks() {
        console.log("Loading skipped stocks from local-storage")
        var stored = JSON.parse(localStorage.getItem("skipped-stocks"));
        if (stored == null) stored = [];
        setSkippedStocks(stored);
        return stored;
    }

    function isSkipped(symbol) {
        return skippedStocks.indexOf(symbol) > -1;
    }

    function clearAllShortlist() {
        localStorage.setItem("shortlisted-stocks", JSON.stringify([]));
        setShortlistedStocks([]);
    }
    function clearAllSkippedList() {
        localStorage.setItem("skipped-stocks", JSON.stringify([]));
        setSkippedStocks([]);
    }

    return (
        <div className={"container-studies"}>
            {user && (<>
                <div className={"study-header"}>Intraday Study

                    <span style={{"float":"right"}}>

                        <button className={"cust-btn "} onClick={() => {
                            clearAllShortlist()
                        }}><i className="dx-icon-trash" style={{"color": "black"}}></i>Clear Shortlist ({shortlistedStocks.length})</button>
                        &nbsp;&nbsp;
                        <button className={"cust-btn btn-default"} onClick={() => {
                            clearAllSkippedList()
                        }}><i className="dx-icon-trash" style={{"color": "black"}}></i>Clear Skipped List ({skippedStocks.length})</button>
                    </span>
                </div>
                <div className={"study-body"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <div className={"study-title study-intraday-boost-title"}>Intraday Boost
                                <select name="direction" id="direction"
                                        className={"cust-btn btn-default"}
                                        style={{width: "25%", float: "right"}}
                                        value={direction}
                                        title={"Choose direction"}
                                        onChange={(event) => changeDirectionFilter(event)}>
                                    <option value="ALL" key={55001}>All</option>
                                    <option value="BULLISH" key={55002}>Bullish</option>
                                    <option value="BEARISH" key={55003}>Bearish</option>
                                </select>
                            </div>
                            <div className={"study-section"}>
                                <div style={{"padding": "5px"}}>
                                    <CheckBox text={"Hide Skipped"} onValueChanged={(e) => setHideSkipped(e.value)}/>
                                </div>
                                {intradayBoostStocks != null && intradayBoostStocks.length !== 0 && (<>
                                        <table className={"study-table"}>
                                            <tr>
                                                <th>Rank</th>
                                                <th>Symbol</th>
                                                <th>LTP</th>
                                                {/*<th>Prev C</th>*/}
                                                <th>%</th>
                                                <th><b>R.Fac</b></th>
                                                <th><b>Diff</b></th>
                                            </tr>
                                            {intradayBoostStocks
                                                .filter((item) => isPerDirectionFilter(item))
                                                .filter((item, index) => !(hideSkipped && isSkipped(item.Symbol)))
                                                .filter((item, index) => index <= 16)
                                                .map((item, index) => (
                                                    <tr className={"clickable-stock " + getHighlightStyleClass(item.Symbol)
                                                        + " " + (isSkipped(item.Symbol) ? "skipped-stock" : "")}
                                                        onClick={() => changeStockToHighlight(item.Symbol)}>
                                                        <td width={"10%"} className={"text-right"}>
                                                            {item.rank !== item.prevRank && item.prevRank !== 0 && <>
                                                                <span className={"dull-text"}>{item.prevRank}</span>
                                                                &nbsp;<i
                                                                className={"" + getRankChangeIcon(item)}></i></>
                                                            }
                                                            &nbsp;<b>{item.rank}</b>&nbsp;
                                                        </td>
                                                        <td width={"25%"}>
                                                            <div>
                                                                {item.Symbol}
                                                                &nbsp;&nbsp;{shortlistedStocks.indexOf(item.Symbol) > -1 &&
                                                                <span><i className="dx-icon-favorites"
                                                                         style={{"color": "darkblue"}}></i></span>}

                                                                {/*&nbsp;{isSkipped(item.Symbol) &&
                                                                <span><i className="dx-icon-notequal"
                                                                         style={{"color": "red"}}></i></span>}*/}
                                                            </div>
                                                        </td>
                                                        <td className={"text-right"}>{item.ltp}</td>
                                                        {/*<td className={"text-right"}>{item.prevClose}</td>*/}
                                                        <td width={"15%"} className={"text-center"}>
                                                            <div
                                                                className={"volume-change " + getGreenOrRedColor(item.percentageChange)}>{item.percentageChange}  </div>
                                                        </td>
                                                        <td width={"15%"} className={"text-center"}>
                                                            <div className={"r-factor"}>{item.rFactor}</div>
                                                        </td>
                                                        <td width={"25%"} className={"text-left"}>
                                                            <div>
                                                                {/*<i className={"change-positive-icon dx-icon-spinup"}></i>*/}
                                                                <b>{Number(item.rfactorChangePercentage).toFixed(2)}</b>%
                                                                &nbsp;&nbsp; <span
                                                                className={"dull-text"}>{Number(item.prevRfactor).toFixed(2)}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </table>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={"col"}>
                            <div className={"study-title study-stock-info-title"}><b>{stockToHighlight}</b> &nbsp;&nbsp;
                                <select name="timeframe" id="timeframe"
                                        className={"cust-btn btn-default"}
                                        style={{width: "25%", float: "right"}}
                                        value={timeframe}
                                        onChange={(event) => changeTimeframe(event)}>
                                    <option value="1" key={11001}>1 min</option>
                                    <option value="3" key={11002}>3 mins</option>
                                    <option value="5" key={110021}>5 mins</option>
                                    <option value="10" key={11003}>10 mins</option>
                                    <option value="15" key={11004}>15 mins</option>
                                    <option value="30" key={11005}>30 mins</option>
                                </select>

                            </div>
                            <div className={"study-stock-info"}>
                                {selectedSymbolDetail != null && selectedSymbolDetail.symbol != null &&
                                    <div>
                                        <div style={{"padding": "5px"}}>
                                            <button className={"cust-btn btn-default"} onClick={() => {
                                                shortlistStock(selectedSymbolDetail.symbol)
                                            }}><i className="dx-icon-favorites"
                                                  style={{"color": "purple"}}></i> SHORTLIST
                                            </button>

                                            { (!isSkipped(selectedSymbolDetail.symbol)) &&
                                            <button className={"cust-btn btn-default"} style={{"float": "right"}}
                                                    onClick={() => {
                                                        skipStockForToday(selectedSymbolDetail.symbol)
                                                    }}><i className="dx-icon-notequal"
                                                          style={{"color": "red"}}></i> SKIP TODAY
                                            </button>}
                                            { (isSkipped(selectedSymbolDetail.symbol)) &&
                                            <button className={"cust-btn btn-default"} style={{"float": "right"}}
                                                    onClick={() => {skipStockForToday(selectedSymbolDetail.symbol)}}>
                                                <i className="dx-icon-revert"
                                                          style={{"color": "blue"}}></i> UNDO SKIP
                                            </button>}
                                        </div>
                                        <table className={"study-table "}>
                                            <tr>
                                                <th>Time</th>
                                                <th>Rank</th>
                                                <th>LTP</th>
                                                <th><b>R.Fac</b></th>
                                                <th><b>R.Fac Chg</b></th>
                                            </tr>
                                            {selectedSymbolDetail.ibDayHistory.filter((item, index) => index <= 100).map((item, index) => (
                                                <tr>
                                                    <td className={"text-left"}>{moment(item.datetime).format('LT')}</td>
                                                    <td className={"text-right"}>
                                                        {item.rank !== item.prevRank && <>
                                                            <span className={"dull-text"}>{item.prevRank}</span>
                                                            &nbsp;<i className={"" + getRankChangeIcon(item)}></i></>
                                                        }
                                                        &nbsp;<b>{item.rank}</b>
                                                    </td>
                                                    <td className={"text-right"}>{item.ltp}</td>
                                                    <td className={"text-center"}>
                                                        <div className={"r-factor"}>{item.rFactor}</div>
                                                    </td>
                                                    <td className={"text-left"}>
                                                        <div>
                                                            {/*<i className={"change-positive-icon dx-icon-spinup"}></i>*/}
                                                            <b>{Number(item.rfactorChangePercentage).toFixed(2)}</b>%
                                                            &nbsp;&nbsp; <span
                                                            className={"dull-text"}>{Number(item.prevRfactor).toFixed(2)}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={"col"}>
                            <div className={"study-title study-stock-info2-title"}><b>{stockToHighlight}</b> - Trade
                                Info
                            </div>

                            {selectedSymbolDetail != null && selectedSymbolDetail.symbol != null &&
                                <div className={"study-stock-trade"}>
                                    <table>
                                        <tr>
                                            <td valign={"top"} align={"right"}>Trade</td>
                                            <td>
                                                <span>
                                                    <label htmlFor={"buy"} className={"cust-btn"}>
                                                        <input id={"buy"}
                                                               type={"radio"}
                                                               name={"tx"}
                                                               value={"BUY"}
                                                               onChange={(e) => setTransactionType(e.target.value)}
                                                               checked={transactionType === "BUY"}/>BUY
                                                    </label> &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <label htmlFor={"sell"} className={"cust-btn"}>
                                                        <input id={"sell"}
                                                               type={"radio"}
                                                               name={"tx"}
                                                               value={"SELL"}
                                                               checked={transactionType === "SELL"}
                                                               onChange={(e) => setTransactionType(e.target.value)}/>SELL
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign={"top"} align={"right"}>Max-Risk</td>
                                            <td>
                                                <select name="maxRiskPrice"
                                                        id="maxRiskPrice"
                                                        className={"cust-btn "}
                                                        style={{width: "70%"}}
                                                        value={maxRiskPrice}
                                                        onChange={(event) => setMaxRiskPrice(event.target.value)}>
                                                    <option value={500} key={9991}>500</option>
                                                    <option value={1000} key={9992}>1000</option>
                                                    <option value={1500} key={9993}>1500</option>
                                                    <option value={2000} key={9994}>2000</option>
                                                    <option value={2500} key={9995}>2500</option>
                                                    {/*{maxRiskPriceRange.map((item, index) => {
                                                        return <option value={item} key={index}>{item}</option>
                                                    })}*/}
                                                </select> Rs
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign={"top"} align={"right"}>Entry Price</td>
                                            <td><input type={'text'} style={{width: '50%'}}
                                                       value={tradeEntryPrice}
                                                       onChange={(e) => setTradeEntryPrice(e.target.value)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign={"top"} align={"right"}>Stop Loss</td>
                                            <td><input type={'text'} style={{width: '50%'}}
                                                       value={tradeStopLoss}
                                                       onChange={(e) => setTradeStopLoss(e.target.value)}/>
                                                <br/>
                                                <small
                                                    style={{color: 'gray'}}>loss: {calculateLoss()} pts</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign={"top"} align={"right"}>Quantity</td>
                                            <td><input type={'text'} style={{width: '40%'}}
                                                       value={tradeQuantity}
                                                       onChange={(e) => setTradeQuantity(e.target.value)}/>

                                                <br/>
                                                <small style={{color: 'gray'}}>1:1 target = <b>{calculateTarget(1)}</b></small><br/>
                                                <small style={{color: 'gray'}}>1:1.5 target
                                                    = <b>{calculateTarget(1.5)}</b></small><br/>
                                                <small style={{color: 'gray'}}>1:2 target = <b>{calculateTarget(2)}</b></small><br/>
                                                <small style={{color: 'gray'}}>1:3 target = <b>{calculateTarget(3)}</b></small><br/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td></td>
                                            <td>
                                                <Button
                                                    style={{fontSize: '18px'}}
                                                    className={"cust-btn init-trade-btn " + (transactionType === 'BUY' ? "btn-primary-green" : 'btn-primary-orange')}
                                                    onClick={() => initiateTrade()}
                                                >{transactionType} {stockToHighlight}</Button>
                                                <br/>
                                                <small style={{color: 'gray'}}>Entry will be "Market" order</small>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col"}>
                            <div className={"study-title study-momentum-title"}>5 Min Momentum
                                {isMomentumStocksLoading && <span title="Refresh" style={{float: 'right'}}><i
                                    className="dx-icon-refresh"></i></span>}
                            </div>
                            {min5MomentumStocks != null && min5MomentumStocks.length !== 0 && (<>
                                    <table className={"study-table"}>
                                        <tr>
                                            <th>#</th>
                                            <th>Symbol</th>
                                            <th><b>%</b></th>
                                        </tr>
                                        {min5MomentumStocks.filter((item, index) => index <= 8).map((item, index) => (
                                            <tr className={"clickable-stock " + getHighlightStyleClass(item.Symbol)}
                                                onClick={() => changeStockToHighlight(item.Symbol)}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <div title={"highlight Stock"}>{item.Symbol}</div>
                                                </td>
                                                <td className={"text-center"}>
                                                    <div className={"volume-change"}>{item.percentageChange}  </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </>
                            )}
                            {/*10 min momentum stocks listing starts*/}
                            <div className={"study-title study-momentum-title"}>10 Min Momentum
                                {isMomentumStocksLoading && <span title="Refresh" style={{float: 'right'}}><i
                                    className="dx-icon-refresh"></i></span>}
                            </div>
                            {min10MomentumStocks != null && min10MomentumStocks.length !== 0 && (<>
                                    <table className={"study-table"}>
                                        <tr>
                                            <th>#</th>
                                            <th>Symbol</th>
                                            <th><b>%</b></th>
                                        </tr>
                                        {min10MomentumStocks.filter((item, index) => index < 7).map((item, index) => (
                                            <tr className={"clickable-stock " + getHighlightStyleClass(item.Symbol)}
                                                onClick={() => changeStockToHighlight(item.Symbol)}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <div title={"highlight Stock"}>{item.Symbol}</div>
                                                </td>
                                                <td className={"text-center"}>
                                                    <div className={"volume-change"}>{item.percentageChange}  </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </>
                            )}
                        </div>
                        {/*momentum stocks list ends*/}
                        <div className={"col"}>
                            <div className={"study-title study-sectors-view-title"}>Sectors View
                                {isMomentumStocksLoading && <span title="Refresh" style={{float: 'right'}}><i
                                    className="dx-icon-refresh"></i></span>}
                            </div>
                            {sectors != null && sectors.length !== 0 && (<>
                                    <table className={"study-table"}>
                                        <tr>
                                            <th>Symbol</th>
                                            <th><b>%</b></th>
                                        </tr>
                                        {sectors
                                            .map((item, index) => (
                                                <tr className={"clickable-stock " + getHighlightStyleClassForSector(item.Symbol)}>
                                                    <td>
                                                        <div className={"clickable-stock"}
                                                             onClick={() => changeSelectedSector(item.Symbol)}
                                                             title={"click to view Stocks"}>{item.Symbol}</div>
                                                    </td>
                                                    <td className={"text-center"}>
                                                        <div
                                                            className={"volume-change" + getGreenOrRedColor(item.percentageChange)}>{item.percentageChange}  </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </table>
                                </>
                            )}
                        </div>
                        <div className={"col"}>
                            <div className={"study-title study-top-sector-stocks-title"}>Sector : {selectedSector}</div>
                            {selectedSectorStocks != null && selectedSectorStocks.length !== 0 && (<>
                                    <table className={"study-table"}>
                                        <tr>
                                            <th>Symbol</th>
                                            <th>LTP</th>
                                            <th>Prev C</th>
                                            <th>%</th>
                                            <th><b>R.Fac</b></th>
                                        </tr>
                                        {selectedSectorStocks.filter((item, index) => index <= 13).map((item, index) => (
                                            <tr className={"clickable-stock " + getHighlightStyleClass(item.Symbol)}
                                                onClick={() => changeStockToHighlight(item.Symbol)}>
                                                <td>
                                                    <div title={"highlight Stock"}>{item.Symbol}</div>
                                                </td>
                                                <td className={"text-right"}>{item.ltp}</td>
                                                <td className={"text-right"}>{item.prevClose}</td>
                                                <td className={"text-center"}>
                                                    <div
                                                        className={"volume-change" + getGreenOrRedColor(item.percentageChange)}>{item.percentageChange}  </div>
                                                </td>
                                                <td width={"20%"} className={"text-center"}>
                                                    <div className={"r-factor"}>{item.rFactor}</div>
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </>
                            )}
                        </div>
                        <div className={"col"}>
                            <div className={"study-title study-high-power-stocks-title"}>High Power Stocks</div>
                            <div>
                                {highPowerStocks != null && highPowerStocks.length !== 0 && (<>
                                        <table className={"study-table"}>
                                            <tr>
                                                <th>Symbol</th>
                                                <th>LTP</th>
                                                <th>Prev C</th>
                                                <th>%</th>
                                                <th><b>T.O.</b></th>
                                            </tr>
                                            {highPowerStocks.filter((item, index) => index <= 16).map((item, index) => (
                                                <tr className={"clickable-stock " + getHighlightStyleClass(item.Symbol)}
                                                    onClick={() => changeStockToHighlight(item.Symbol)}>
                                                    <td>
                                                        <div title={"highlight Stock"}>{item.Symbol}</div>
                                                    </td>
                                                    <td className={"text-right"}>{item.ltp}</td>
                                                    <td className={"text-right"}>{item.prevClose}</td>
                                                    <td width={"20%"} className={"text-center"}>
                                                        <div
                                                            className={"volume-change" + getGreenOrRedColor(item.percentageChange)}>{item.percentageChange}  </div>
                                                    </td>
                                                    <td width={"20%"} className={"text-center"}>
                                                        <div className={"r-factor"}>{item.to}</div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    </>
                                )}
                            </div>
                        </div>

                    </div>
                    {/*2nd row ENDS*/}

                    {/*3rd row STARTS */}
                    <div className={"row"}>
                        <div className={"col"}>
                            <div className={"study-title study-pre-market-title"}>F & O Pre-market Movers</div>
                            <div>
                                {fnoPremarketMovers != null && fnoPremarketMovers.length !== 0 && (<>
                                        <table className={"study-table"}>
                                            <tr>
                                                <th>Symbol</th>
                                                <th>%</th>
                                                <th>Price</th>
                                                <th>Pre-Action</th>
                                                {/*<th>Date-Time</th>*/}
                                            </tr>
                                            {fnoPremarketMovers.filter((item, index) => index <= 25).map((item, index) => (
                                                <tr className={"clickable-stock " + getHighlightStyleClass(item.Symbol)}
                                                    onClick={() => changeStockToHighlight(item.Symbol)}>
                                                    <td>
                                                        <div title={"highlight Stock"}>{item.Symbol}</div>
                                                    </td>
                                                    <td width={"20%"} className={"text-center"}>
                                                        <div
                                                            className={"volume-change" + getGreenOrRedColor(item.percentageChange)}>{item.percentageChange}  </div>
                                                    </td>

                                                    <td className={"text-right"}>{item.price}</td>
                                                    <td className={"text-right"}>{item.priceAction}</td>
                                                    {/*<td className={"text-right"}>{item.dateTime}</td>*/}
                                                </tr>
                                            ))}
                                        </table>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className={"col"}>
                        </div>
                        <div className={"col"}>
                        </div>
                    </div>
                    {/*3rd row ENDS */}

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </>)}
        </div>
    )
}

export default IntradayStudyMain;