import React, {useMemo} from 'react';
import {useHistory} from "react-router-dom";
import {useAuth} from '../../contexts/auth';
import './user-panel.scss';
import ContextMenu, {Position} from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';

export default function ({menuMode}) {
    const {user, signOut, signIn} = useAuth();
    const history = useHistory();

    function navigateToProfile() {
        history.push("/preferences");
    }

    const authenticatedItems = [
        {
            text: 'Preferences',
            icon: 'preferences',
            onClick: navigateToProfile,
            size: 10
        },
        {
            text: 'Logout',
            icon: 'runner',
            onClick: signOut,
            size: 10
        }
    ];

    const menuItems = useMemo(() => (
        authenticatedItems
    ), [signOut]);

    const userIcon = () => {
        if (user) {
            return (<div
                style={{
                    background: `url(${user.avatarUrl}) no-repeat #fff`,
                    backgroundSize: 'cover'
                }}
                className={'user-image'}/>)
        } else {
            return (
                <i className="dx-icon dx-icon-user"></i>
            )
        }
    };

    return (
        <>
            <div className={'user-panel'}>
                <div className={'user-info'}>
                    <div className={'image-container'}>
                        {userIcon()}
                    </div>
                    <div className={'user-name'}>Hello {user ?
                        (<span>{user.name} <i className="dx-icon dx-icon-spindown"></i></span>)
                        : 'Guest!'}</div>
                </div>

                {menuMode === 'context' && (
                    <ContextMenu
                        items={menuItems}
                        target={'.user-button'}
                        showEvent={'dxclick'}
                        width={210}
                        cssClass={'user-menu'}>
                        <Position my={'top center'} at={'bottom center'}/>
                    </ContextMenu>
                )}
                {menuMode === 'list' && (
                    <List className={'dx-toolbar-menu-action'} items={menuItems}/>
                )}
            </div>
        </>
    );
}
