import axios from "axios";
import {API_BASE_URL} from "../../../constants/UrlServiceConstants";
import {apiHeader} from "../headers/ApiHeaders";

const uploadBhavCopy = (file) => {

    let formData = new FormData();
    formData.append('csv_file', file);

    return axios.post(`${API_BASE_URL}/admin/oi/upload`,
        formData,
        {headers: {"Content-Type": 'multipart/form-data'}, Authorization: apiHeader(),})
};


const fetchAndSycInstruments = () => {
    return axios.get(`${API_BASE_URL}/admin/instruments/fetch-and-load`, {headers: {Authorization: apiHeader()}})
};

export default {uploadBhavCopy, fetchAndSycInstruments};
