import {useState} from "react";
import {Button} from "devextreme-react/button";
import CustomStrategyService from "../../../api/service/CustomStrategyService";
import Notification from "../../../utils/notification-toast"

function CustomStrategyModal({closeModal, setCustomStrategyVal}) {
    const [newCustomStrategyTitle, setNewCustomStrategyTitle] = useState('');

    function saveCustomConfig() {
        const customStrategyConfig = {
            type: "CUSTOM_STRATEGY"
        }

        const reqBodyData = {
            isAutomatic: false,
            type: "CUSTOM_STRATEGY",
            title: newCustomStrategyTitle,
            strategyConfig: customStrategyConfig
        }

        CustomStrategyService.saveCustomStrategyConfig(reqBodyData)
            .then(resp => {
                if (resp.status === 200) {
                    var newStrategyId = resp.data.id
                    console.log('New strategy created with id: ' + newStrategyId)
                    setCustomStrategyVal(newStrategyId)
                    Notification.success("New strategy created successfully.")
                } else {
                    Notification.error("There was a problem creating new strategy.")
                }
            }).catch(err => {
            Notification.error("There was a problem creating new strategy.")
        });
        closeModal()
    }

    return (<>
        <div style={{}}>
            <div className={""}>
                <table style={{width: "100%", lineHeight: '25px'}} className={"cust-bg-light"}>
                    <tbody>
                    <tr>
                        <td align={"right"}>
                            Title
                        </td>
                        <td>
                            <input type={'text'} style={{width: '90%'}}
                                   value={newCustomStrategyTitle}
                                   onChange={(e) => setNewCustomStrategyTitle(e.target.value)}/>
                        </td>
                    </tr>
                    <tr key={7}>
                        <td>{' '}</td>
                        <td>
                            <span style={{float: 'left'}}>
                                <Button
                                    style={{fontSize: '18px'}}
                                    className={"cust-btn btn-primary"}
                                    onClick={() => saveCustomConfig()}
                                >Add Strategy</Button>
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>)
}

export default CustomStrategyModal;