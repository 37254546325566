const isEntryExitTimeValid = (entryTimeInput, exitTimeInput) => {
    const exitHour = exitTimeInput.substring(0, 2)
    const exitMins = exitTimeInput.substring(3, 5)
    const exitTime = new Date();
    exitTime.setHours(exitHour);
    exitTime.setMinutes(exitMins);
    exitTime.setSeconds(0)

    const entryHour = entryTimeInput.substring(0, 2)
    const entryMins = entryTimeInput.substring(3, 5)
    const entryTime = new Date();
    entryTime.setHours(entryHour);
    entryTime.setMinutes(entryMins);
    entryTime.setSeconds(0)

    return entryTime < exitTime && entryTime !== exitTime;

}

const setNextExitTime = (entryTimeInput, exitTimeInput) => {
    const exitHour = exitTimeInput.substring(0, 2)
    const exitMins = exitTimeInput.substring(3, 5)
    const exitTime = new Date();
    exitTime.setHours(exitHour);
    exitTime.setMinutes(exitMins);
    exitTime.setSeconds(0)

    const entryHour = entryTimeInput.substring(0, 2)
    const entryMins = entryTimeInput.substring(3, 5)
    const entryTime = new Date();
    entryTime.setHours(entryHour);
    entryTime.setMinutes(entryMins);
    entryTime.setSeconds(0)

    let isOK = false;
    let newExitTimeVal = "" + exitHour + ":" + exitMins;
    if (exitTime > entryTime) {
        isOK = true
    } else {
        const curTime = new Date();
        curTime.setHours(entryHour)
        curTime.setMinutes(entryMins)
        const extraMinutes = 5 * 60000;
        const newExitTime = new Date(curTime.getTime() + extraMinutes);
        const newHour = newExitTime.getHours() < 10 ? '0' + newExitTime.getHours() : newExitTime.getHours();
        const newMin = newExitTime.getMinutes() < 10 ? '0' + newExitTime.getMinutes() : newExitTime.getMinutes()
        newExitTimeVal = "" + newHour + ":" + newMin;
        if (newHour === 15 && newMin > 25) {
            newExitTimeVal = "" + 15 + ":" + 25;
        }

    }

    return {isValid: isOK, newExitTime: newExitTimeVal}
}

export default {isEntryExitTimeValid, setNextExitTime};