import React from 'react';

const OAuthKitePage = () => (
    <React.Fragment>
        <h2 className={'content-block'}>Kite Connect</h2>
        <div className={'content-block'}>
            <h1>Authenticating please wait...</h1>
        </div>
    </React.Fragment>
);

export default OAuthKitePage;
