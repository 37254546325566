import React, {useState} from 'react';
import CustomStrategyList from "./CustomStrategyList";
import CustomStrategyModal from "./strategies/CustomStrategyModal";
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '30%',
        left: '40%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '350px',
        height: '200px'
    }
};
const CustomStrategyComponent = ({setCustomStrategy}) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
    }

    function closeModal() {
        setIsOpen(false);
    }

    Modal.setAppElement('#root');

    function setCustomStrategyVal(data) {
        setCustomStrategy(data)
    }

    return (<>
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="New Strategy"
        >
            <div>
                <button style={{float: 'right'}} onClick={closeModal}>&times;</button>
            </div>
            <div>New Custom Strategy</div>
            <br/>
            <CustomStrategyModal closeModal={closeModal} setCustomStrategyVal={setCustomStrategyVal}/>
        </Modal>
        <div className={"strategies"} style={{display: 'flex', justifyContent: 'left', marginTop: '5px'}}>
            <h6>Strategies </h6>
            &nbsp;&nbsp;&nbsp;
            <button onClick={openModal} style={{float: "right", cursor: "pointer"}} className={"btn-primary-blue"}>+
                New Strategy
            </button>
        </div>
        <CustomStrategyList setCustomStrategyVal={setCustomStrategyVal}/>
    </>)
}

export default CustomStrategyComponent;