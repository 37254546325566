import React, {useEffect, useState} from 'react';
import "./study.scss"
import {useAuth} from "../../contexts/auth";
import TredCodeService from "../../api/service/TredCodeService";
import moment from 'moment';

const SwingStudyMain = () => {
    const {user} = useAuth();
    const [isStocksLoading, setIsStocksLoading] = useState(true);
    const [lastUpdated, setLastUpdated] = useState(moment());

    const [longTermUpStocks, setLongTermUpStocks] = useState([])
    const [longTermDownStocks, setLongTermDownStocks] = useState([])
    const [sectors, setSectors] = useState([])

    const [selectedSector, setSelectedSector] = useState("")
    const [stockToHighlight, setStockToHighlight] = useState("")

    const autoRefreshMs = 10000; // 10 secs
    let interval;
    useEffect(() => {
        loadSwingCenterData();
        interval = setInterval(() => {
            loadSwingCenterData();
        }, autoRefreshMs);
        return () => clearInterval(interval);
    }, []);

    function loadSwingCenterData() {
        setIsStocksLoading(true);
        TredCodeService.getSwingCenterData()
            .then(response => {
                if (response.status === 200) {
                    setSectors(response.data.sectors);
                    setLongTermUpStocks(response.data.longTermUpStocks);
                    setLongTermDownStocks(response.data.longTermDownStocks);

                    setIsStocksLoading(false);
                    setLastUpdated(moment());
                }
            }).catch(error => {
            console.error(error)
        });
    }

    function changeSelectedSector(sectorName) {
        setSelectedSector(sectorName);
    }

    function changeStockToHighlight(stockName) {
        setStockToHighlight(stockName);
    }

    function getHighlightStyleClass(stockName) {
        if (stockName === stockToHighlight) return " highlighted-stock";
        return "";
    }
    function getSwingTodaysDateClass(stockName, swingDate) {
        if(swingDate == null) return "";
        if (stockName === stockToHighlight) return "";
        var swingDateFormatted = moment(swingDate, timeformat).format('ll')
        var todayFormatted = moment().format('ll')
        if(todayFormatted === swingDateFormatted) return "swing-today";
        return "";
    }

    function getGreenOrRedColor(value) {
        // if value is negative, return red styled class,
        // if value is positive, return green styled class
        if (value >= 0) return " change-positive";
        else return " change-negative"
    }
    const timeformat = "yyyy-MM-DD";

    return (
        <div className={"container-studies"}>
            {user && (<>
                <div className={"study-header"}>
                    <div className={"row"}>
                        <div className={"col"}>Swing Study</div>
                        <div className={"col text-right"}>Last updated: {lastUpdated.format('llll')}</div>
                    </div>
                </div>
                <div className={"study-body"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <div className={"study-title study-sectors-view-title"}>Index Performance
                                {isStocksLoading && <span title="Refresh" style={{float: 'right'}}><i
                                    className="dx-icon-refresh"></i></span>}
                            </div>
                            {sectors != null && sectors.length !== 0 && (<>
                                    <table className={"study-table"}>
                                        <tr>
                                            {sectors.map((item, index) => (<>
                                                <td className={"text-left"}><div className={"clickable-stock"}
                                                         onClick={() => changeSelectedSector(item.Symbol)}
                                                         title={"click to view Stocks"}>{item.Symbol}</div></td>
                                            </>))}
                                        </tr>
                                        <tr>
                                            {sectors.map((item, index) => (<>
                                                <td className={"text-center"}><div style={{width:'50px'}}
                                                    className={"volume-change " + getGreenOrRedColor(item.percentageChange)}>{item.percentageChange}  </div></td>
                                            </>))}
                                        </tr>
                                    </table>
                                </>
                            )}
                        </div>
                    </div>

                    <br/>

                    <div className={"row"}>
                        <div className={"col"}>
                            <div className={"study-title study-swing-upside-title"}>Long-Term Breakout - UP SIDE
                                {isStocksLoading && <span title="Refresh" style={{float: 'right'}}><i
                                    className="dx-icon-refresh"></i></span>}
                            </div>
                            {longTermUpStocks != null && longTermUpStocks.length !== 0 && (<>
                                    <table className={"study-table"}>
                                        <tr>
                                            <th>#</th>
                                            <th>Symbol</th>
                                            <th>Sector</th>
                                            <th>LTP</th>
                                            <th>Prev.Close</th>
                                            <th><b>%</b></th>
                                            <th>Date</th>

                                        </tr>
                                        {longTermUpStocks.filter((item, index) => index <= 100).map((item, index) => (
                                            <tr className={"clickable-stock "  + getSwingTodaysDateClass(item.Symbol, item.dateTime) + getHighlightStyleClass(item.Symbol) }
                                                onClick={() => changeStockToHighlight(item.Symbol)}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <div>{item.Symbol}</div>
                                                </td>
                                                <td>
                                                    <div>{item.sector}</div>
                                                </td>
                                                <td className={"text-center"}>
                                                    <div>{item.ltp}  </div>
                                                </td>
                                                <td className={"text-center"}>
                                                    <div>{item.prevClose}  </div>
                                                </td>
                                                <td className={"text-center"}>
                                                    <div className={"volume-change " + getGreenOrRedColor(item.percentageChange)}>{item.percentageChange}  </div>
                                                </td>
                                                <td className={"text-right"}>
                                                    <div>{moment(item?.dateTime, timeformat).format('ll')}</div>
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </>
                            )}
                        </div>
                        <div className={"col"}>
                            <div className={"study-title study-swing-downside-title"}>Long-Term Breakout - DOWN SIDE
                                {isStocksLoading && <span title="Refresh" style={{float: 'right'}}><i
                                    className="dx-icon-refresh"></i></span>}
                            </div>
                            {longTermDownStocks != null && longTermDownStocks.length !== 0 && (<>
                                    <table className={"study-table"}>
                                        <tr>
                                            <th>#</th>
                                            <th>Symbol</th>
                                            <th>Sector</th>
                                            <th>LTP</th>
                                            <th>Prev.Close</th>
                                            <th><b>%</b></th>
                                            <th>Date</th>
                                        </tr>
                                        {longTermDownStocks.filter((item, index) => index <= 100).map((item, index) => (
                                            <tr className={"clickable-stock "  + getSwingTodaysDateClass(item.Symbol, item.dateTime) + getHighlightStyleClass(item.Symbol) }
                                                onClick={() => changeStockToHighlight(item.Symbol)}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <div title={"highlight Stock"}>{item.Symbol}</div>
                                                </td>
                                                <td>
                                                    <div>{item.sector}</div>
                                                </td>
                                                <td className={"text-center"}>
                                                    <div>{item.ltp}  </div>
                                                </td>
                                                <td className={"text-center"}>
                                                    <div>{item.prevClose}  </div>
                                                </td>
                                                <td className={"text-center"}>
                                                    <div className={"volume-change " + getGreenOrRedColor(item.percentageChange)}>{item.percentageChange}  </div>
                                                </td>
                                                <td className={"text-right"}>
                                                    <div>{moment(item?.dateTime, timeformat).format('ll')}</div>
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </>
                            )}
                        </div>
                    </div>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </>)}
        </div>
    )
}

export default SwingStudyMain;