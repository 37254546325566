function stringToDate(strDate) {
    return new Date(strDate).toISOString().slice(0, 10);
}

const addMissedDate = (dateVal) => {
    const date = new Date(dateVal);
    date.setDate(date.getDate() + 1);
    return stringToDate(date)
};

const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-')
};


const formatDateTime = (date) => {
    return new Date(date).toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-') + ' ' + new Date(date).toLocaleTimeString()
};

const getMinusDate = (dateVal) => {
    const date = new Date(dateVal);
    date.setDate(date.getDate() - 1);
    if (date.getDay() === 0) {
        date.setDate(date.getDate() - 2);
    }
    if (date.getDay() === 6) {
        date.setDate(date.getDate() - 1);
    }
    return stringToDate(date)
};

const getPlusDate = (dateVal) => {
    const date = new Date(dateVal);
    date.setDate(date.getDate() + 1);
    if (date.getDay() === 0) {
        date.setDate(date.getDate() + 1);
    }
    if (date.getDay() === 6) {
        date.setDate(date.getDate() + 2);
    }
    return stringToDate(date)
};

const getMinusHours = (inputDate, numberOfHours) => {
    const date = new Date(inputDate);
    date.setHours(date.getHours() - numberOfHours)
    return date;
};

const convertDateToMs = (inputDate) => {
    const date = new Date(inputDate);
    date.setHours(0, 0, 0, 0)
    return date.getTime();
};

const convertDateTimeToMs = (inputDate) => {
    const date = new Date(inputDate);
    return date.getTime();
};

const convertMsToDate = (milliSeconds) => {
    return new Date(milliSeconds);
};

const removeTimeFromDate = (date) =>{
    date.setMinutes(0)
    date.setHours(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date;
}

export default {
    stringToDate,
    addMissedDate,
    formatDate,
    formatDateTime,
    getMinusDate,
    getPlusDate,
    convertDateToMs,
    convertMsToDate,
    getMinusHours,
    convertDateTimeToMs,
    removeTimeFromDate
};
