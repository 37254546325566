import axios from "axios";
import {API_BASE_URL} from "../../../constants/UrlServiceConstants";
import {apiHeader} from "../headers/ApiHeaders";

const saveTradingHolidays = (bodyData) => {
    return axios.post(`${API_BASE_URL}/admin/holidays`,
        bodyData,
        {headers: {Authorization: apiHeader()}})
};

const loadTradingHolidays = (dateInMs) => {
    return axios.get(`${API_BASE_URL}/admin/holidays?year=${dateInMs}`,
        {headers: {Authorization: apiHeader()}})
};

const deleteTradingHolidays = (id) => {
    return axios.delete(`${API_BASE_URL}/admin/holidays/${id}`,
        {headers: {Authorization: apiHeader()}})
};
export default {saveTradingHolidays, loadTradingHolidays, deleteTradingHolidays};
