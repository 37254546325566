import React, {useEffect, useState} from 'react'
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import StrategyService from "../../../../api/service/StrategyService"
import Notification from "../../../../utils/notification-toast"
import AutoTradeHelper from "../../helper/AutoTradeHelper";
import TradeInfoComp from "./CustomTradeInfoComp";
import Validation from "../StrategyValidation";
import CustomStrategyService from "../../../../api/service/CustomStrategyService";
import {Button} from "devextreme-react/button";
import {CheckBox} from "devextreme-react/check-box";

const CustomStrategy = (props) => {

    // tab config
    const tabIndex = props.tabIndex;
    const setTabIndex = props.setTabIndex;
    const customStrategyId = props.id;

    // init state
    const maxRiskPriceRange = AutoTradeHelper.maxRiskPrice;
    const [isTradeStarted, setIsTradeStarted] = useState(false); // todo: need to check on page load

    // strategy config
    const [loading, setLoading] = useState(false)
    const [customStrategyTitle, setCustomStrategyTitle] = useState('')
    const [transactionType, setTransactionType] = useState("SELL")
    const [configType, setConfigType] = useState("manual")
    const [selectedSymbol, setSelectedSymbol] = useState('');
    const [strikeSelectionType, setStrikeSelectionType] = useState(0);
    const [strikeDistanceFromAtm, setStrikeDistanceFromAtm] = useState(0);
    const [strikePremiumRs, setStrikePremiumRs] = useState(0);
    const [weeksToExpiry, setWeeksToExpiry] = useState(0);
    const [bias, setBias] = useState("NEUTRAL");
    const [waitAndTradePercent, setWaitAndTradePercent] = useState(10);

    const [stopLossPercent, setStopLossPercent] = useState(25);
    const [minStopLossPoints, setMinStopLossPoints] = useState(70);
    const [maxStopLossPoints, setMaxStopLossPoints] = useState(100);
    const [trailStopLossPercent, setTrailStopLossPercent] = useState(25);

    const [targetPoints, setTargetPoints] = useState(0);
    const [maxRiskPrice, setMaxRiskPrice] = useState(5000);
    const [entryTime, setEntryTime] = useState(`09:30`);
    const [exitTime, setExitTime] = useState(`15:15`);
    const [isValidTimeRange, setIsValidTimeRange] = useState(true);
    const [onExpiryDayOnly, setOnExpiryDayOnly] = useState(true);

    const [strategyConfigInfo, setStrategyConfigInfo] = useState({})
    const [isInitTradeButtonDisabled, setIsInitTradeButtonDisabled] = useState(false)

    useEffect(() => {
        fetchStrategyConfigInfo()
    }, [])

    const fetchStrategyConfigInfo = () => {
        setLoading(true)
        CustomStrategyService.getCustomStrategyConfig(customStrategyId).then(resp => {
            if (resp.status === 200) {
                const data = resp.data;
                setStrategyConfigInfo(data)
                setConfigType(data.automatic === true ? 'automatic' : 'manual');
                setStrikeSelectionType(data.strategyConfig.strikeSelectionType);
                setStrikeDistanceFromAtm(data.strategyConfig.strikeDistanceFromAtm);
                setStrikePremiumRs(data.strategyConfig.strikePremiumRs);
                setCustomStrategyTitle(data.strategyTitle);
                setSelectedSymbol(data.strategyConfig.instrument);
                setTransactionType(data.strategyConfig.transactionType);
                setWaitAndTradePercent(data.strategyConfig.waitAndTradePercent);
                setStopLossPercent(data.strategyConfig.stopLossPercent);
                setMinStopLossPoints(data.strategyConfig.minStopLossPoints);
                setMaxStopLossPoints(data.strategyConfig.maxStopLossPoints);
                setTrailStopLossPercent(data.strategyConfig.trailStopLossPercent);
                setTargetPoints(data.strategyConfig.targetPoints);
                setMaxRiskPrice(data.strategyConfig.maxRiskPrice);
                setWeeksToExpiry(data.strategyConfig.weeksToExpiry);
                setBias(data.strategyConfig.bias != null ? data.strategyConfig.bias : "NEUTRAL");

                setOnExpiryDayOnly(data.strategyConfig.onExpiryDayOnly != null ? data.strategyConfig.onExpiryDayOnly : false);
                if (data.automatic && data.entryTime !== null && data.exitTime !== null) {
                    setEntryTime(data.entryTime.substring(0, 5))
                    setExitTime(data.exitTime.substring(0, 5))
                }
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false)
            console.log(err)
        });
    }

    function changeConfigType(e) {
        setConfigType(e.target.value)
    }

    function changeStrikeSelectionType(e) {
        setStrikeSelectionType(e.target.value);
    }

    function initiateCustomStrategyTrade() {
        setIsInitTradeButtonDisabled(true);
        setTimeout(() => setIsInitTradeButtonDisabled(false), 1000 * 5);
        CustomStrategyService.initiateNewCustomStrategyTrade(customStrategyId).then(resp => {
            if (resp.status === 200) {
                setIsTradeStarted(true)
                Notification.success("New Trade initiated successfully.")
            } else {
                setIsTradeStarted(false)
                Notification.error("Unable to initiate a new trade!")
            }
        }).catch(err => {
            console.log(err)
            Notification.error("There was a error trying to initiate new trade")
        })
    }

    function saveCustomConfig() {
        const strategyConfig = {
            instrument: selectedSymbol,
            type: strategyConfigInfo.strategyConfig.type,
            transactionType: transactionType,
            strikeSelectionType: strikeSelectionType,
            strikeDistanceFromAtm: strikeDistanceFromAtm,
            strikePremiumRs: strikePremiumRs,
            waitAndTradePercent: waitAndTradePercent,
            stopLossPercent: stopLossPercent,
            minStopLossPoints,
            maxStopLossPoints,
            trailStopLossPercent,
            targetPoints,
            weeksToExpiry,
            maxRiskPrice,
            onExpiryDayOnly,
            bias
        }

        const reqBodyData = {
            isAutomatic: configType === 'automatic',
            type: strategyConfigInfo.strategyConfig.type,
            id: strategyConfigInfo.id,
            title: customStrategyTitle,
            strategyConfig: strategyConfig
        }

        if (configType === "automatic") {
            reqBodyData['entryTime'] = entryTime;
            reqBodyData['exitTime'] = exitTime;
        }
        // sideNavOuterToolbar(reqBodyData)
        CustomStrategyService.updateCustomStrategy(reqBodyData)
            .then(resp => {
                if (resp.status === 200) {
                    Notification.success("Successfully Saved.")
                    fetchStrategyConfigInfo()
                } else {
                    Notification.error("Unable to Save.")
                }
            })
            .catch(err => {
                Notification.error("Unable to Save.")
            });
    }

    function deleteCustomConfig() {
        var configIdToDelete = strategyConfigInfo.id;
        CustomStrategyService.deleteCustomStrategy(configIdToDelete)
            .then(resp => {
                if (resp.status === 200) {
                    Notification.success("Strategy Deleted successfully.")
                    props.setStrategy()
                } else {
                    Notification.error("There was a problem deleting the strategy")
                }
            })
            .catch(err => {
                Notification.error("There was a problem deleting the strategy. Error: " + err);
            });
    }

    return (<>
        <div className={""}>
            <span style={{fontSize: '17px'}}>{strategyConfigInfo.strategyTitle}</span>
            <span style={{float: 'right'}}>
                    <button className={"cust-btn btn-primary"} onClick={() => props.setStrategy()}>
                    <i className="dx-icon-chevrondoubleleft"></i> Back</button>
                    </span>
            <br/>
            <div>
                {loading && (<>Loading strategy <span className={'dot-loader'}></span></>)}
                {!loading && (<div>
                    {Object.keys(strategyConfigInfo).length !== 0 && "automatic" in strategyConfigInfo ? (<div>
                        {strategyConfigInfo.automatic === true && (<div>
                            <div className={"info-field-green"}>
                                <i className="dx-icon-info"></i>{' '}
                                Strategy Configured to be triggered Automatically {' '}
                                <button
                                    className={"cust-btn btn-default"}
                                    onClick={() => setTabIndex(1)}>
                                    <i className="dx-icon-edit"></i>{' '}
                                    Change
                                </button>
                            </div>
                            <div>
                                Entry Time: {entryTime}  &nbsp;|&nbsp;
                                Exit Time: {exitTime}
                            </div>
                        </div>)}
                        {strategyConfigInfo.automatic !== true && (<div>
                            <div className={"info-field-green"}>
                                <button
                                    className={`cust-btn ${isInitTradeButtonDisabled ? 'btn-primary-disabled' : 'btn-primary-green'} init-trade-btn`}
                                    onClick={() => initiateCustomStrategyTrade()}
                                    title={isInitTradeButtonDisabled ? 'Disabled' : 'Click to initialize new trade'}
                                    disabled={isInitTradeButtonDisabled}>
                                    Initiate New Trade &nbsp; <i
                                    className="dx-icon-chevrondoubleright"></i>
                                </button>
                            </div>
                        </div>)}
                    </div>) : (<div className={"info-field"}>
                        Strategy Settings are missing {' '}
                        <button
                            className={"cust-btn btn-default"}
                            onClick={() => setTabIndex(2)}>Add now
                        </button>
                    </div>)}
                </div>)}

            </div>
            {!loading && (<Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                <TabList>
                    <Tab><span><i className="dx-icon-formula"></i> New Trade Info</span></Tab>
                    <Tab><span><i className="dx-icon-preferences"></i> Settings</span></Tab>
                </TabList>

                <TabPanel>
                    <TradeInfoComp strategyConfigId={strategyConfigInfo.id}/>
                </TabPanel>

                <TabPanel>
                    <div className={"tab-content"}>
                        <table style={{width: "400px"}} className={"cust-bg-light"}>
                            <tbody>
                            <tr>
                                <td style={{paddingBottom: "7px"}}>Title: <input type={'text'} style={{width: '60%'}}
                                                                                 value={customStrategyTitle}
                                                                                 onChange={(e) => setCustomStrategyTitle(e.target.value)}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Initiate Trade: &nbsp;
                                    <label htmlFor={"manual"} className={"cust-btn"}>
                                        <input id={"manual"}
                                               type={"radio"}
                                               name={"config"}
                                               value={"manual"}
                                               checked={configType === "manual"}
                                               onChange={(e) => changeConfigType(e)}/>Manually
                                    </label> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <label htmlFor={"auto"} className={"cust-btn"}>
                                        <input id={"auto"}
                                               type={"radio"}
                                               name={"config"}
                                               value={"automatic"}
                                               onChange={(e) => changeConfigType(e)}
                                               checked={configType === "automatic"}/>Automatically
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {configType === "automatic" && (<>
                                        <table style={{width: "100%"}}>
                                            <tr key={101}>
                                                <td valign={"top"} align={"right"}>
                                                    <label htmlFor={"entryTime"}>Entry Time</label>
                                                </td>
                                                <td>
                                                    <select id="entryTime"
                                                            style={{width: "40%"}}
                                                            value={entryTime}
                                                            className={"cust-btn btn-default"}
                                                            onChange={(event) => {
                                                                setEntryTime(event.target.value)
                                                                const isValid = Validation.isEntryExitTimeValid(event.target.value, exitTime);
                                                                if (isValid) {
                                                                    setIsValidTimeRange(true)
                                                                } else {
                                                                    setIsValidTimeRange(false)
                                                                }

                                                                const validExitTime = Validation.setNextExitTime(event.target.value, exitTime);
                                                                if (!validExitTime.isValid) {
                                                                    setExitTime(validExitTime.newExitTime)
                                                                }
                                                            }}>
                                                        {AutoTradeHelper.entryTimeRange?.map((item, index) => (
                                                            <option value={item} key={index}>{item}</option>))}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr key={102}>
                                                <td valign={"top"} align={"right"}>
                                                    <label htmlFor={"exitTime"}>Exit Time </label>
                                                </td>
                                                <td>
                                                    <select name="exitTime"
                                                            id="exitTime"
                                                            style={{width: "40%"}}
                                                            value={exitTime}
                                                            className={"cust-btn btn-default"}
                                                            onChange={(event) => {
                                                                const isValid = Validation.isEntryExitTimeValid(entryTime, event.target.value);
                                                                if (isValid) {
                                                                    setIsValidTimeRange(true)
                                                                    setExitTime(event.target.value)
                                                                } else {
                                                                    setIsValidTimeRange(false)
                                                                }
                                                            }}>
                                                        {AutoTradeHelper.exitTimeRange?.map((item, index) => (
                                                            <option value={item} key={index}>{item}</option>))}
                                                    </select>
                                                </td>
                                            </tr>
                                        </table>
                                        {!isValidTimeRange && (<div>
                                            <small style={{color: 'red'}}>* Exit time must be
                                                greater
                                                than entry
                                                time.</small>
                                            <br/>
                                        </div>)}
                                        <div style={{textAlign: 'center'}}>
                                            <CheckBox text={"On Expiry Day Only"} value={onExpiryDayOnly}
                                                      onValueChanged={(e) => setOnExpiryDayOnly(e.value)}/>
                                        </div>
                                    </>)}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <table style={{width: "400px"}} className={"cust-bg-light"}>
                            <tbody>
                            <tr>
                                <td align={"right"}>
                                    Index
                                </td>
                                <td>
                                <span>
                                    <select name="selectedSymbol" id="selectedSymbol"
                                            className={"cust-btn btn-default"}
                                            style={{width: "75%"}}
                                            value={selectedSymbol}
                                            onChange={(event) => setSelectedSymbol(event.target.value)}>
                                        <option value="NIFTY" key={991}>Nifty</option>
                                        <option value="BANK_NIFTY" key={992}>Bank Nifty</option>
                                        <option value="FIN_NIFTY" key={993}>Fin Nifty</option>
                                        <option value="NIFTY_MIDCAP" key={994}>Midcap Nifty</option>
                                        <option value="SENSEX" key={996}>Sensex</option>
                                        <option value="BANKEX" key={999}>Bankex</option>
                                    </select>
                                </span>
                                </td>
                            </tr>

                            <tr>
                                <td align={"right"} valign={"top"}>
                                    <label>Choose Type</label><br/>
                                </td>
                                <td>
                                    <span>
                                        <label htmlFor={"sell"} className={"cust-btn"}>
                                            <input id={"sell"}
                                                   type={"radio"}
                                                   name={"tx"}
                                                   value={"SELL"}
                                                   checked={transactionType === "SELL"}
                                                   onChange={(e) => setTransactionType(e.target.value)}/>Option Selling
                                        </label> &nbsp;&nbsp;&nbsp;&nbsp;
                                        <label htmlFor={"buy"} className={"cust-btn"}>
                                            <input id={"buy"}
                                                   type={"radio"}
                                                   name={"tx"}
                                                   value={"BUY"}
                                                   onChange={(e) => setTransactionType(e.target.value)}
                                                   checked={transactionType === "BUY"}/>Option Buying
                                        </label>
                                    </span>
                                    <hr/>
                                </td>
                            </tr>

                            <tr key={201}>
                                <td valign={"top"} align={"right"}>
                                    <label htmlFor={"strike"}>Strike Selection</label>
                                </td>
                                <td>
                                    <label htmlFor={"atm-based"} className={"cust-btn"}>
                                        <input id={"atm-based"}
                                               type={"radio"}
                                               name={"strikeSelectionType"}
                                               value={"ATM_BASED"}
                                               checked={strikeSelectionType === "ATM_BASED"}
                                               onChange={(e) => changeStrikeSelectionType(e)}/>ATM Based
                                    </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {strikeSelectionType === "ATM_BASED" && (<><select
                                        name="strikeDistanceFromAtm" id="strikeDistanceFromAtm"
                                        className={"cust-btn btn-default"}
                                        style={{width: "30%"}}
                                        value={strikeDistanceFromAtm}
                                        onChange={(event) => setStrikeDistanceFromAtm(event.target.value)}>
                                        <option value="-4" key={96}>ITM 4</option>
                                        <option value="-3" key={97}>ITM 3</option>
                                        <option value="-2" key={98}>ITM 2</option>
                                        <option value="-1" key={99}>ITM 1</option>
                                        <option value="0" key={100}>ATM</option>
                                        <option value="1" key={101}>OTM 1</option>
                                        <option value="2" key={102}>OTM 2</option>
                                        <option value="3" key={103}>OTM 3</option>
                                        <option value="4" key={104}>OTM 4</option>
                                        <option value="5" key={105}>OTM 5</option>
                                    </select>
                                    </>)}
                                    <br/>
                                    <label htmlFor={"premium-based"} className={"cust-btn"}>
                                        <input id={"premium-based"}
                                               type={"radio"}
                                               name={"strikeSelectionType"}
                                               value={"PREMIUM_BASED"}
                                               onChange={(e) => changeStrikeSelectionType(e)}
                                               checked={strikeSelectionType === "PREMIUM_BASED"}/>Premium Pt
                                    </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {strikeSelectionType === "PREMIUM_BASED" && (<><select name="strikePremiumRs"
                                                                                           id="strikePremiumRs"
                                                                                           className={"cust-btn btn-default"}
                                                                                           style={{width: "30%"}}
                                                                                           value={strikePremiumRs}
                                                                                           onChange={(event) => setStrikePremiumRs(event.target.value)}>
                                        <option value="30" key={3009}>30</option>
                                        <option value="50" key={3011}>50</option>
                                        <option value="80" key={3012}>80</option>
                                        <option value="100" key={311}>100</option>
                                        <option value="130" key={3112}>130</option>
                                        <option value="150" key={321}>150</option>
                                        <option value="180" key={3212}>180</option>
                                        <option value="200" key={331}>200</option>
                                        <option value="230" key={3312}>230</option>
                                        <option value="250" key={341}>250</option>
                                        <option value="280" key={3412}>280</option>
                                        <option value="300" key={351}>300</option>
                                        <option value="350" key={352}>350</option>
                                        <option value="400" key={361}>400</option>
                                        <option value="500" key={3612}>500</option>
                                    </select>
                                    </>)}
                                    <hr/>
                                </td>
                            </tr>
                            <tr key={202}>
                                <td valign={"top"} align={"right"}>
                                    <label htmlFor={"entryPrice"}>Wait & Trade</label>
                                </td>
                                <td>
                                    <select name="entryPrice"
                                            id="entryPrice"
                                            className={"cust-btn btn-default"}
                                            style={{width: "30%"}}
                                            value={waitAndTradePercent}
                                            onChange={(event) => setWaitAndTradePercent(event.target.value)}>
                                        {[...Array(61.0)].map((item, index) => {
                                            let entryPriceThreshold = index;
                                            let entryPriceThresholdHalf = index + 0.5;
                                            return <>
                                                <option value={entryPriceThreshold}
                                                        key={entryPriceThreshold}>{entryPriceThreshold}</option>
                                                <option value={entryPriceThresholdHalf}
                                                        key={entryPriceThresholdHalf}>{entryPriceThresholdHalf}</option>
                                            </>
                                        })}
                                    </select> %
                                    <hr/>
                                </td>
                            </tr>
                            <tr key={211}>
                                <td valign={"top"} align={"right"}>
                                    <label htmlFor={"stopLoss"}>Stop Loss</label>
                                </td>
                                <td>
                                    <select name="stopLoss"
                                            id="stopLoss"
                                            className={"cust-btn btn-default"}
                                            style={{width: "40%"}}
                                            value={stopLossPercent}
                                            onChange={(event) => setStopLossPercent(event.target.value)}>
                                        {[...Array(100)].map((item, index) => {
                                            let stopLossEntry = index;
                                            let stopLossEntryHalf = index + 0.5;
                                            return <>
                                                <option value={stopLossEntry}
                                                        key={stopLossEntry}>{stopLossEntry}</option>
                                                <option value={stopLossEntryHalf}
                                                        key={stopLossEntryHalf}>{stopLossEntryHalf}</option>
                                            </>
                                        })}
                                    </select> %<br/>
                                    Min:
                                    <select name="minStopLossPoints"
                                            id="minStopLossPoints"
                                            className={"cust-btn btn-default"}
                                            style={{width: "30%"}}
                                            value={minStopLossPoints}
                                            onChange={(event) => setMinStopLossPoints(event.target.value)}>
                                        {[...Array(21)].map((item, index) => {
                                            var val = (index * 5);
                                            return <option value={val} key={index}>{val}</option>
                                        })}
                                    </select> pts,
                                    Max:
                                    <select name="maxStopLossPoints"
                                            id="maxStopLossPoints"
                                            className={"cust-btn btn-default"}
                                            style={{width: "30%"}}
                                            value={maxStopLossPoints}
                                            onChange={(event) => setMaxStopLossPoints(event.target.value)}>
                                        {[...Array(81)].map((item, index) => {
                                            var val = (index * 5);
                                            return <option value={val} key={index}>{val}</option>
                                        })}
                                    </select> pts
                                    <br/>
                                </td>
                            </tr>
                            <tr key={212}>
                                <td valign={"top"} align={"right"}>
                                    <label htmlFor={"trailStopLoss"}>Trail SL</label>
                                </td>
                                <td>
                                    <select name="trailStopLoss"
                                            id="trailStopLoss"
                                            className={"cust-btn btn-default"}
                                            style={{width: "30%"}}
                                            value={trailStopLossPercent}
                                            onChange={(event) => setTrailStopLossPercent(event.target.value)}>
                                        {[...Array(21)].map((item, index) => {
                                            var val = (index * 5);
                                            return <>
                                                <option value={val} key={val}>{val}</option>
                                            </>
                                        })}
                                    </select> %
                                    <br/>
                                    <span style={{fontSize: "11px"}}>Set to 0 to not trail SL</span>
                                </td>
                            </tr>
                            <tr key={215}>
                                <td valign={"top"} align={"right"}>
                                    <label htmlFor={"targetPoints"}>Target</label>
                                </td>
                                <td>
                                    <select name="targetPoints"
                                            id="targetPoints"
                                            className={"cust-btn btn-default"}
                                            style={{width: "30%"}}
                                            value={targetPoints}
                                            onChange={(event) => setTargetPoints(event.target.value)}>
                                        {[...Array(41)].map((item, index) => {
                                            var val = (index * 10);
                                            return <option value={val} key={val}>{val}</option>
                                        })}
                                    </select> pts<br/>
                                    <span style={{fontSize: "11px"}}>Set to 0 to not create any targets</span>
                                    <hr/>
                                </td>
                            </tr>

                            <tr key={2211}>
                                <td valign={"top"} align={"right"}>
                                    <label htmlFor={"bias"}>Bias</label>
                                </td>
                                <td>
                                    <select name="bias" id="bias"
                                            className={"cust-btn btn-default"}
                                            style={{width: "75%"}}
                                            value={bias}
                                            onChange={(event) => setBias(event.target.value)}>
                                        <option value="NEUTRAL" key={31001}>Neutral</option>
                                        <option value="BULLISH" key={31011}>Bullish</option>
                                        <option value="BEARISH" key={31012}>Bearish</option>
                                    </select>
                                    <br/>
                                </td>
                            </tr>

                            <tr key={221}>
                                <td valign={"top"} align={"right"}>
                                    <label htmlFor={"weeksToExpiry"}>Choose Expiry</label>
                                </td>
                                <td>
                                    <select name="weeksToExpiry" id="weeksToExpiry"
                                            className={"cust-btn btn-default"}
                                            style={{width: "75%"}}
                                            value={weeksToExpiry}
                                            onChange={(event) => setWeeksToExpiry(event.target.value)}>
                                        <option value="0" key={1001}>Current Week</option>
                                        <option value="1" key={1011}>Next Week</option>
                                        <option value="2" key={1012}>Far (2 weeks away)</option>
                                    </select>
                                    <br/>
                                </td>
                            </tr>

                            <tr key={222}>
                                <td align={"right"}>
                                    <label htmlFor={"maxRiskPrice"}>Max Risk</label>
                                </td>
                                <td>
                                    <select name="maxRiskPrice"
                                            id="maxRiskPrice"
                                            className={"cust-btn btn-default"}
                                            style={{width: "50%"}}
                                            value={maxRiskPrice}
                                            onChange={(event) => setMaxRiskPrice(event.target.value)}>
                                        {maxRiskPriceRange.map((item, index) => {
                                            return <option value={item} key={index}>{item}</option>
                                        })}
                                    </select> Rs
                                </td>
                            </tr>
                            <tr key={251}>
                                <td>{' '}</td>
                                <td><span style={{float: 'left'}}>
                                        <Button style={{fontSize: '15px'}}
                                                className={"cust-btn btn-primary"}
                                                disabled={!isValidTimeRange}
                                                onClick={() => saveCustomConfig()}>Save Strategy</Button> &nbsp;
                                    <Button style={{fontSize: '13px'}}
                                            className={"cust-btn btn-danger"}
                                            onClick={() => deleteCustomConfig()}>Delete</Button>
                                     </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </TabPanel>
            </Tabs>)}
            <div>
            </div>
        </div>
    </>)
}
export default CustomStrategy;
