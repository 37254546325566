import axios from "axios";
import {API_BASE_URL} from "../../constants/UrlServiceConstants";
import {apiHeader} from "./headers/ApiHeaders";

const saveCustomStrategyConfig = (bodyData) => {
    return axios.post(`${API_BASE_URL}/strategies/custom/configs`, bodyData, {headers: {Authorization: apiHeader()}})
};

const getCustomStrategyConfig = (customStrategyId) => {
    return axios.get(`${API_BASE_URL}/strategies/custom/configs/${customStrategyId}`, {headers: {Authorization: apiHeader()}})
};
const getCustomStrategyConfigs = () => {
    return axios.get(`${API_BASE_URL}/strategies/custom/configs`, {headers: {Authorization: apiHeader()}})
};

const updateCustomStrategy = (bodyData) =>{
    return axios.post(`${API_BASE_URL}/strategies/custom/configs`, bodyData, {headers: {Authorization: apiHeader()}})
}
const deleteCustomStrategy = (customStrategyId) =>{
    return axios.post(`${API_BASE_URL}/strategies/custom/configs/${customStrategyId}/delete`, {}, {headers: {Authorization: apiHeader()}})
}

const fetchStrategyNewTradeInfo = (customStrategyId) => {
    return axios.get(`${API_BASE_URL}/strategies/custom/${customStrategyId}`, {headers: {Authorization: apiHeader()}})
}

const initiateNewCustomStrategyTrade = (customStrategyId) => {
    return axios.post(`${API_BASE_URL}/strategies/custom/${customStrategyId}/initiate-trade`, {},{headers: {Authorization: apiHeader()}})
}

export default {
    saveCustomStrategyConfig,
    getCustomStrategyConfig,
    getCustomStrategyConfigs,
    updateCustomStrategy,
    deleteCustomStrategy,
    fetchStrategyNewTradeInfo,
    initiateNewCustomStrategyTrade
}