import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {adminNavigation, memberNavigation, userNavigation} from '../../app-navigation';
import {useNavigation} from '../../contexts/navigation';
import {useScreenSize} from '../../utils/media-query';
import './side-navigation-menu.scss';

import * as events from 'devextreme/events';
import {useAuth} from "../../contexts/auth";
import {useHistory} from "react-router-dom";

export default function (props) {
    const {
        children,
        selectedItemChanged,
        openMenu,
        compactMode,
        onMenuReady
    } = props;
    const sidebarStatus = !compactMode;
    const {isLarge} = useScreenSize();
    const {user, loading} = useAuth();
    const history = useHistory();
    let navigation = userNavigation;

    if (user?.role === 'MEMBER') {
        navigation = memberNavigation
    }
    if (user?.role === 'ADMIN') {
        navigation = adminNavigation
    }

    function normalizePath() {
        return navigation.map((item) => {
            if (item.path && !(/^\//.test(item.path))) {
                item.path = `/${item.path}`;
            }
            return {...item, expanded: false};
        });
    }

    function navigate(path) {
        history.push(path);
    }

    const items = useMemo(
        normalizePath,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    console.log(items)
    const {navigationData: {currentPath}} = useNavigation();

    const treeViewRef = useRef();
    const wrapperRef = useRef();
    const getWrapperRef = useCallback((element) => {
        const prevElement = wrapperRef.current;
        if (prevElement) {
            events.off(prevElement, 'dxclick');
        }

        wrapperRef.current = element;
        events.on(element, 'dxclick', e => {
            openMenu(e);
        });
    }, [openMenu]);

    useEffect(() => {
        const treeView = treeViewRef.current && treeViewRef.current.instance;
        if (!treeView) {
            return;
        }

        if (currentPath !== undefined) {
            treeView.selectItem(currentPath);
            treeView.expandItem(currentPath);
        }

        if (compactMode) {
            treeView.collapseAll();
        }
    }, [currentPath, compactMode]);

    return (
        <div
            className={'dx-swatch-additional side-navigation-menu'}
            ref={getWrapperRef}
        >
            <div className={'menu-container'}>

                <ul className={"th-sidebar"}>
                    {
                        items.map((item, index) => (
                            <li key={index} onClick={() => navigate(item.path)}>
                                {/*<Link to={`${item.path}`}>*/}
                                <div>
                                    <span><i className={`icon dx-icon-${item.icon}`}></i> &nbsp;</span>
                                    {sidebarStatus && (
                                        <span>{item.text}</span>
                                    )}
                                </div>
                                {/*</Link>*/}
                            </li>
                        ))
                    }
                </ul>
            </div>

        </div>
    );
}
