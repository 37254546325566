import {toast} from "react-hot-toast";

const success = (msg) => {
    toast.success(msg);
};

const error = (msg) => {
    toast.error(msg);
};

const warning = (msg) => {
    toast(msg)
};

export default {success, error, warning}
