import React, {useEffect, useState} from "react";
import CustomStrategyService from "../../api/service/CustomStrategyService";
import "./auto-trade.scss"
import moment from 'moment';

function CustomStrategyList({setCustomStrategyVal}) {
    const [customStrategyListData, setCustomStrategyListData] = useState([])
    useEffect(() => {
        loadCustomStrategies();
        return () => {
        }
    }, [])

    function loadCustomStrategies() {
        CustomStrategyService.getCustomStrategyConfigs()
            .then(response => {
                if (response.status === 200) {
                    setCustomStrategyListData(response.data)
                }
            }).catch(error => {
            console.error(error)
        });
    }

    function setCustomStrategyValue(customConfigId) {
        setCustomStrategyVal(customConfigId)
    }

    const timeformat = "HH:mm:ss";
    return (<>
        <div className={'strategies'}>
            <ul className={"strategy-list"}>
                {customStrategyListData.length !== 0 && (
                    <>
                        {customStrategyListData.map((item, index) => (
                            <li key={index} onClick={() => setCustomStrategyValue(item.id)}>
                                <span className={"btn-primary"} style={{margin: 0}}>{index + 1}. {item.strategyTitle}</span>
                                <p style={{margin: 0, marginLeft: '10px'}}>
                                    <small>
                                        <span className={"label-yellow"}>{item?.strategyConfig?.instrument}</span>&nbsp;
                                        &nbsp;{item.strategyConfig.transactionType === 'BUY' && <span className={"label-blue"}>Buy</span> }
                                        {item.strategyConfig.transactionType === 'SELL' && <span className={"label-red"}>Sell</span> }&nbsp;

                                        {item?.strategyConfig.bias != 'NEUTRAL' &&
                                            <span className={"label-pink"}>{item?.strategyConfig?.bias}</span>
                                        }

                                        <div style={{marginTop: '5px'}}>
                                        {item?.automatic === true && <span className={"label-orangered"}> Auto -&nbsp;
                                            {moment(item?.entryTime, timeformat).format('h:mm a')}
                                            &nbsp;to {moment(item?.exitTime, timeformat).format('h:mm a')}
                                        </span>}
                                        {item?.automatic === false && <span className={"label-lightblue"}>Manual</span>}
                                        &nbsp;{item?.automatic === true && item?.strategyConfig?.onExpiryDayOnly === true &&
                                        <span className={"label-purple"}>Expiry Only</span>}
                                        </div>

                                        W&T: {item.strategyConfig?.waitAndTradePercent}%,
                                        SL: {item?.strategyConfig?.stopLossPercent}%,
                                        Max Risk: Rs. {item.strategyConfig?.maxRiskPrice}
                                    </small>
                                </p>
                            </li>
                        ))}
                    </>
                )}
            </ul>
        </div>
    </>)
}

export default CustomStrategyList;