// Note: Set environment variables prefixed as "REACT_APP_*" in environment

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//export const API_BASE_URL = 'http://localhost:8080/api';

export const OAUTH2_REDIRECT_URL = process.env.REACT_APP_OAUTH2_REDIRECT_URL;
//export const OAUTH2_REDIRECT_URL = 'http://localhost:3000/oauth/code/response';
export const OAUTH2_REDIRECT_URL_ERROR = 'https://oauth2.example.com/auth?error'

// kite url
export const KITE_OAUTH_REDIRECT_URL = process.env.REACT_APP_KITE_OAUTH_REDIRECT_URL;
//export const KITE_OAUTH_REDIRECT_URL = 'http://localhost:3000/zerodha';
export const KITE_OAUTH_REDIRECT_URL_ERROR = 'http://localhost:3000/zerodha/error';

console.log("\n\nConfigurations: ");
console.log("API_BASE_URL: " + API_BASE_URL);
console.log("OAUTH2_REDIRECT_URL: " + OAUTH2_REDIRECT_URL);
console.log("KITE_OAUTH_REDIRECT_URL: " + KITE_OAUTH_REDIRECT_URL);
