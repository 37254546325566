import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import appInfo from './app-info';
import routes from './app-routes';
import {SideNavOuterToolbar as SideNavBarLayout} from './layouts';
import {Footer} from './components';
import Admin from "./pages/admin/Admin";
import {useAuth} from "./contexts/auth";

export default function () {
    const {user} = useAuth();
    return (
        <SideNavBarLayout title={appInfo.appName}>
            <Switch>
                {routes.map(({path, component}) => (
                    <Route
                        exact
                        key={path}
                        path={path}
                        component={component}
                    />
                ))}

                {user && user?.role === "ADMIN"?
                (<Route
                    exact
                    key={`/user/admin`}
                    path={`/user/admin`}
                    component={Admin}
                />) : ''
                }
                <Redirect to={'/home'}/>
            </Switch>
            <Footer>
                Copyright © {new Date().getFullYear()} {appInfo.title}
            </Footer>
        </SideNavBarLayout>
    );
}
