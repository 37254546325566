const getYears = (year, count) => {
    const startYear = year -1;
    const endYear = year + count;

    // returns years
    let years = [];
    Array(Math.ceil((endYear - startYear))).fill(startYear).map((x, y) => {
        years.push(x + y)
    });
    return years;
};

export default {getYears}
