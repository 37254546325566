import React, {useEffect, useState} from 'react';
import {Popup} from "devextreme-react/popup";
import Button from "devextreme-react/button";
import {Toaster} from "react-hot-toast";
import OHLCService from "../../api/service/admin/OHLCService";
import DateHelper from "../../helper/DateHelper";
import DateBox from "devextreme-react/date-box";
import {DATE_FORMAT} from "../../constants/DateConstants";
import {NumberBox} from "devextreme-react";
import SelectBox from 'devextreme-react/select-box';
import Notification from "../../utils/notification-toast"

const OhlcTabComp = () => {
    const [loading, setLoading] = useState(false);
    const symbolNames = ["NSE:NIFTY BANK", "NSE:NIFTY 50", "BANKNIFTY", "NIFTY", "INDIA VIX"];
    const [curSymbol, setCurSymbol] = useState(symbolNames[0]);
    const [ohlcList, setOHLCList] = useState([]);

    // OHLC fields
    const [name, setName] = useState(symbolNames[0]);
    const [date, setDate] = useState(new Date().toLocaleDateString());
    const [open, setOpen] = useState(0);
    const [high, setHigh] = useState(0);
    const [low, setLow] = useState(0);
    const [close, setClose] = useState(0);

    // edit OHLC
    const [editOHLCId, setEditOHLCId] = useState(0);
    const [editName, setEditName] = useState('');
    const [editDate, setEditDate] = useState(new Date().toLocaleDateString());
    const [editOpen, setEditOpen] = useState(0);
    const [editHigh, setEditHigh] = useState(0);
    const [editLow, setEditLow] = useState(0);
    const [editClose, setEditClose] = useState(0);

    const [newOHLcModalStatus, setNewOHLcModalStatus] = useState(false);
    const [editOHLcModalStatus, setEditOHLcModalStatus] = useState(false);
    const [removeOHLCModalStatus, setRemoveOHLCModalStatus] = useState(false);
    const [deleteOHLC, setDeleteOHLC] = useState({});
    const [currentPivotInfo, setCurrentPivotInfo] = useState([]);
    const [currentCamarillaInfo, setCurrentCamarillaInfo] = useState([]);

    useEffect(() => {
        fetchOHLC()
    }, [curSymbol]);

    const closeOHLCModal = () => {
        setNewOHLcModalStatus(!newOHLcModalStatus)
    };

    const closeEditOHLCModal = () => {
        setEditOHLcModalStatus(!editOHLcModalStatus)
    };


    function saveOHLC() {
        setCurrentPivotInfo([])
        const newDate = new Date(date);
        const formattedDate = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
        const bodyData = {
            id: null,
            name: name,
            date: formattedDate,
            open: open,
            high: high,
            low: low,
            close: close
        };
        createOrUpdateOHLC(bodyData)
        setNewOHLcModalStatus(false)
    }

    function updateOHLC() {
        setCurrentPivotInfo([])
        const newDate = new Date(editDate);
        const formattedDate = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
        const bodyData = {
            id: editOHLCId,
            name: editName,
            date: formattedDate,
            open: editOpen,
            high: editHigh,
            low: editLow,
            close: editClose
        };
        createOrUpdateOHLC(bodyData)
        setEditOHLcModalStatus(false)
    }

    function createOrUpdateOHLC(requestBody) {
        setCurrentPivotInfo([])
        OHLCService.saveNewOHLC(requestBody)
            .then(resp => {
                if (resp.status === 200) {
                    Notification.success("new OHLC saved");
                    fetchOHLC()
                } else {
                    Notification.error("Unable to save new OHLC")
                }
            })
            .catch(err => {
                Notification.error("Unable to save new OHLC");
            });
    }

    const confirmDeleteOHLC = (info) => {
        setRemoveOHLCModalStatus(true)
        setDeleteOHLC(info)
    };

    function fetchOHLC() {
        OHLCService.fetchOHLCByName(curSymbol).then((resp) => {
            setOHLCList(resp.data.ohlcInfoEntries);
        }).catch()
    }

    function doDeleteOHLC(isDelete) {
        setCurrentPivotInfo([])
        setLoading(true)
        if (isDelete) {
            OHLCService.deleteOHLC(deleteOHLC?.id).then(resp => {
                if (resp.status === 200) {
                    fetchOHLC()
                    setRemoveOHLCModalStatus(false);
                    Notification.success("Deletion success")
                } else {
                    setRemoveOHLCModalStatus(false);
                    Notification.error("Unable to delete")
                }
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            })
        } else {
            setDeleteOHLC({})
            setRemoveOHLCModalStatus(false);
        }
    }

    function editOHLC(item) {
        setEditOHLCId(item.id)
        setEditName(item.name)
        setEditOHLCId(item.id)
        setEditDate(new Date(item.ohlcDateEpochMs).toLocaleDateString())
        setEditOpen(item.open)
        setEditHigh(item.high)
        setEditLow(item.low)
        setEditClose(item.close)
        setEditOHLcModalStatus(!editOHLcModalStatus)
    }

    function setSelectedOhlcEntry(item) {
        setCurrentPivotInfo(item.pivotPoints)
        setCurrentCamarillaInfo(item.camarillaLevels)
    }

    return (
        <>
            <div>
                <div className="row">
                    <div className="column" style={{width:'40%'}}>
                        <div className={"ohlc-container"}>
                            <div className={"ohlc-body"}>
                                <table className={"ohlc-table"}>
                                    <thead>
                                    <tr>
                                        <th colSpan={8}>
                                        <span style={{float: 'left'}}>
                                            <span style={{
                                                fontSize: '15px',
                                                color: 'grey',
                                                fontWeight: 'normal'
                                            }}>
                                            Instrument {''}
                                        </span>
                                        <select title={"Select the trading year"}
                                                className={"cust-btn"}
                                                style={{fontSize: '15px'}}
                                                defaultValue={curSymbol}
                                                onChange={(e) => {
                                                    setCurSymbol(e.target.value)
                                                }}
                                        >
                                        {symbolNames.map((item, index) =>
                                            (
                                                <option key={index}>
                                                    {item}
                                                </option>
                                            )
                                        )}
                                        </select>
                                            {
                                                loading && (
                                                    <span style={{color: '#9e9e9e'}}>
                                                        {" "}Loading <span className={'dot-loader'}/></span>
                                                )
                                            }
                                            </span>
                                            <span style={{float: 'right'}}>
                                            <button className={"cust-btn btn-primary"}
                                                onClick={() => setNewOHLcModalStatus(!newOHLcModalStatus)}
                                                 >
                                                <strong>
                                                    <i className="dx-icon-add"></i>
                                                    New OHLC</strong>
                                            </button>
                                            </span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>#</th>
                                        <th className={"text-right"}>Open</th>
                                        <th className={"text-right"}>High</th>
                                        <th className={"text-right"}>Low</th>
                                        <th className={"text-right"}>Close</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {ohlcList.length !== 0 ?
                                        ohlcList?.map((item, index) =>
                                            (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td className={"text-right"}>{item.ohlc.open}</td>
                                                    <td className={"text-right"}>{item.ohlc.high}</td>
                                                    <td className={"text-right"}>{item.ohlc.low}</td>
                                                    <td className={"text-right"}>{item.ohlc.close}</td>
                                                    <td className={"text-right"}>{DateHelper.formatDate(item.ohlc.ohlcDateEpochMs)}</td>
                                                    <td className={"text-right"}>
                                                        <button
                                                            className={"cust-btn btn-success edit-ohlc-btn"}
                                                            title={"Edit OHLC"}
                                                            onClick={() => editOHLC(item.ohlc)}
                                                        >
                                                            <i className="dx-icon-edit"></i>
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            className={"cust-btn btn-danger delete-ohlc-btn"}
                                                            title={"Delete OHLC"}
                                                            onClick={() => confirmDeleteOHLC(item.ohlc)}
                                                        >
                                                            <i className="dx-icon-trash"></i>
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            className={"cust-btn btn-info edit-ohlc-btn"}
                                                            title={"Pivot Points info"}
                                                            onClick={() => setSelectedOhlcEntry(item)}
                                                        >
                                                            <i className="dx-icon-info"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                        :
                                        <tr>
                                            <td colSpan={4}> No Data found</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>

                                <Popup
                                    width={300}
                                    height={600}
                                    visible={newOHLcModalStatus}
                                    title={"New OHLC"}
                                    showCloseButton={true}
                                    onHiding={closeOHLCModal}
                                >
                                    <div>
                                        <label style={{color: 'grey'}}>Instrument</label>
                                        <SelectBox items={symbolNames}
                                                   defaultValue={curSymbol}
                                                   value={curSymbol}
                                                   onValueChanged={(e) => setName(e.value)}/>
                                        <br/>
                                        <label style={{color: 'grey'}} htmlFor={"date"}>Date</label>
                                        <DateBox
                                            defaultValue={new Date()}
                                            displayFormat={DATE_FORMAT}
                                            width={'auto'}
                                            type="date"
                                            onValueChanged={(e) => {
                                                setDate(e.value)
                                            }}
                                        />
                                        <br/>
                                        <label style={{color: 'grey'}}>Open</label>
                                        <NumberBox defaultValue={open}
                                                   showClearButton={true}
                                                   onValueChange={(e) => {
                                                       if (e !== null) {
                                                           setOpen(e)
                                                       }
                                                   }}/>
                                        <br/>
                                        <label style={{color: 'grey'}}>High</label>
                                        <NumberBox defaultValue={high}
                                                   showClearButton={true}
                                                   onValueChange={(e) => {
                                                       if (e !== null) {
                                                           setHigh(e)
                                                       }
                                                   }}/>
                                        <br/>
                                        <label style={{color: 'grey'}}>Low</label>
                                        <NumberBox defaultValue={low}
                                                   showClearButton={true}
                                                   onValueChange={(e) => {
                                                       if (e !== null) {
                                                           setLow(e)
                                                       }
                                                   }}/>
                                        <br/>
                                        <label style={{color: 'grey'}}>Close</label>
                                        <NumberBox defaultValue={close}
                                                   showClearButton={true}
                                                   onValueChange={(e) => {
                                                       if (e !== null) {
                                                           setClose(e)
                                                       }
                                                   }}/>
                                        <br/>
                                        <div style={{justifyContent: 'center', display: 'flex'}}>
                                            <Button className="button"
                                                    text={`Save`}
                                                    onClick={saveOHLC}
                                                    type="default"/>
                                        </div>
                                    </div>
                                </Popup>

                                {/*edit pop up*/}
                                <Popup
                                    width={400}
                                    height={600}
                                    visible={editOHLcModalStatus}
                                    title={"Edit OHLC"}
                                    showCloseButton={true}
                                    onHiding={closeEditOHLCModal}
                                >
                                    <div>
                                        {editName}
                                        <br/>
                                        <label style={{color: 'grey'}} htmlFor={"date"}>Date</label>
                                        <DateBox
                                            defaultValue={editDate}
                                            value={editDate}
                                            displayFormat={DATE_FORMAT}
                                            width={'auto'}
                                            type="date"
                                            onValueChanged={(e) => {
                                                setEditDate(e.value)
                                            }}
                                        />
                                        <br/>
                                        <label style={{color: 'grey'}}>Open</label>
                                        <NumberBox defaultValue={editOpen}
                                                   value={editOpen}
                                                   showClearButton={true}
                                                   onValueChange={(e) => {
                                                       if (e !== null) {
                                                           setEditOpen(e)
                                                       }
                                                   }}/>
                                        <br/>
                                        <label style={{color: 'grey'}}>High</label>
                                        <NumberBox defaultValue={editHigh}
                                                   showClearButton={true}
                                                   value={editHigh}
                                                   onValueChange={(e) => {
                                                       if (e !== null) {
                                                           setEditHigh(e)
                                                       }
                                                   }}/>
                                        <br/>
                                        <label style={{color: 'grey'}}>Low</label>
                                        <NumberBox defaultValue={editLow}
                                                   value={editLow}
                                                   showClearButton={true}
                                                   onValueChange={(e) => {
                                                       if (e !== null) {
                                                           setEditLow(e)
                                                       }
                                                   }}/>
                                        <br/>
                                        <label style={{color: 'grey'}}>Close</label>
                                        <NumberBox defaultValue={editClose}
                                                   value={editClose}
                                                   showClearButton={true}
                                                   onValueChange={(e) => {
                                                       if (e !== null) {
                                                           setEditClose(e)
                                                       }
                                                   }}/>
                                        <br/>
                                        <div style={{justifyContent: 'center', display: 'flex'}}>
                                            <Button className="button"
                                                    text={`Update`}
                                                    onClick={updateOHLC}
                                                    type="default"/>
                                        </div>
                                    </div>
                                </Popup>
                            </div>

                            {/*Delete OHLC Modal*/}
                            <div>
                                <Popup
                                    width={500}
                                    height={200}
                                    visible={removeOHLCModalStatus}
                                    title={"Delete OHLC"}
                                    showCloseButton={true}
                                    onHiding={() => {
                                        setDeleteOHLC({})
                                        setRemoveOHLCModalStatus(!removeOHLCModalStatus)
                                    }}
                                >
                                    <div>
                                        <div>
                                        <span
                                            style={{color: 'grey'}}>Name: </span><span><strong>
                                    {deleteOHLC?.name}
                                            </strong></span><br/>
                                            <span
                                                style={{color: 'grey'}}>Date: </span><span><strong>
                                    {new Date(deleteOHLC?.ohlcDateEpochMs).toDateString()}
                                        </strong></span>
                                        </div>
                                        <br/>
                                        <div>
                                            <Button className="button"
                                                    text={`Yes`}
                                                    onClick={() => doDeleteOHLC(true)}
                                                    type="default"/>
                                            <span>{' '}</span>
                                            <Button className="button"
                                                    text={`No`}
                                                    onClick={() => doDeleteOHLC(false)}
                                                    type="normal"/>
                                        </div>
                                    </div>
                                </Popup>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className={"pt-div"}>
                            {
                                currentPivotInfo.length !== 0 ?
                                    (
                                        <table className={"pivot-table"}>
                                            <tr>
                                                <th>Pivot Type</th>
                                                <th>Pivot Price</th>
                                            </tr>
                                            {
                                                currentPivotInfo.map((item, index) =>
                                                    (
                                                        <tr key={index}>
                                                            <td>{item.pivotType}</td>
                                                            <td align={"left"}>&nbsp;{item.pivotPrice}</td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                        </table>
                                    )
                                    : (
                                        <span>please click the info [<i className="dx-icon-info"></i>] button for pivot points</span>
                                    )
                            }
                        </div>
                    </div>
                    <div className="column">
                        <div className={"pt-div"}>
                            {
                                currentCamarillaInfo.length !== 0 ?
                                    (
                                        <table className={"pivot-table"}>
                                            <tr>
                                                <th>Camarilla Level</th>
                                                <th>Price</th>
                                            </tr>
                                            {
                                                currentCamarillaInfo.map((item, index) =>
                                                    (
                                                        <tr key={index}>
                                                            <td>{item.levelType}</td>
                                                            <td align={"left"}>&nbsp;{item.pivotPrice}</td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                        </table>
                                    )
                                    : (
                                        <span> </span>
                                    )
                            }
                        </div>
                    </div>
                </div>

                <Toaster/>
            </div>
        </>
    )
}
export default OhlcTabComp;