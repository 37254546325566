import React, {useEffect, useState} from 'react';
import Toolbar, {Item} from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import {Template} from 'devextreme-react/core/template';
import {useAuth} from "../../contexts/auth";
import LoginPopUp from "../login-form/login-pop-up/login-pop-up";
import DateHelper from "../../helper/DateHelper";
import {useHistory} from "react-router-dom";
import Notification from "../../utils/notification-toast";
import moment from 'moment';
import TimeService from "../../api/service/TimeService";
import TredCodeService from "../../api/service/TredCodeService";

export default ({menuToggleEnabled, title, toggleMenu}) => {
    const {user} = useAuth();
    const [loginPopupVisible, setLoginPopupVisible] = useState(false);
    const history = useHistory();
    const [timeNow, setTimeNow] = useState({});

    const closeLoginPopup = () => {
        setLoginPopupVisible(!loginPopupVisible);
    };

    const autoRefreshMs = 5000; // every 5 sec
    let interval;
    useEffect(() => {
        loadCurrentTime();
        interval = setInterval(() => {
            loadCurrentTime();
        }, autoRefreshMs);
        return () => clearInterval(interval);
    }, []);

    function loadCurrentTime() {
        TimeService.getCurrentTime()
            .then(response => {
                if (response.status === 200) {
                    setTimeNow(moment(response.data.datetime));
                }
            }).catch(error => {
            console.error(error)
        });
    }

    return (
        <>
            <header className={'header-component'}>
                <Toolbar className={'header-toolbar'}>
                    <Item
                        visible={menuToggleEnabled}
                        location={'before'}
                        widget={'dxButton'}
                        cssClass={'menu-button'}
                    >
                        <Button icon="menu" stylingMode="text" onClick={toggleMenu}/>
                    </Item>
                    <Item
                        location={'before'}
                        cssClass={'header-title'}
                        text={title}
                        visible={!!title}
                    />

                    <Item
                        cssClass={'header-current-time'}
                        text={moment(timeNow).format('LTS')}
                        />

                    <Item
                        location={'after'}
                        locateInMenu={'auto'}
                        menuItemTemplate={'userPanelTemplate'}
                    >
                        {!user ? (
                                <span>
                                    <span className={"guest-user-text"}>Hello Guest!</span>
                                    <Button
                                        height={'auto'}
                                        width={'auto'}
                                        stylingMode="contained"
                                        text="Login"
                                        icon="key"
                                        onClick={() => {
                                            setLoginPopupVisible(true)
                                        }}
                                        type="default"/>
                                </span>
                            )
                            : (
                                <span className={""}>

                                <Button
                                    className={'user-button authorization'}
                                    width={"auto"}
                                    height={'100%'}
                                    stylingMode={'text'}>
                                    <UserPanel menuMode={'context'}/>
                                </Button>
                                </span>
                            )}

                        {/*Login modal*/}
                        <LoginPopUp loginPopupVisible={loginPopupVisible} closeLoginPopup={closeLoginPopup}/>
                    </Item>
                    <Template name={'userPanelTemplate'}>
                        <UserPanel menuMode={'list'}/>
                    </Template>
                </Toolbar>
            </header>
        </>
    )
};
