import axios from "axios";
import {API_BASE_URL} from "../../constants/UrlServiceConstants";
import {apiHeader} from "./headers/ApiHeaders";

const getMomentumStocks = () => {
    return axios.get(`${API_BASE_URL}/tredcode/momentum`, {headers: {Authorization: apiHeader()}})
};


const getIntradayBoostStocks = () => {
    return axios.get(`${API_BASE_URL}/tredcode/intraday-boost`, {headers: {Authorization: apiHeader()}})
};


const getHighPowerStocks = () => {
    return axios.get(`${API_BASE_URL}/tredcode/high-power`, {headers: {Authorization: apiHeader()}})
};

const getPremarketMovers = () => {
    return axios.get(`${API_BASE_URL}/tredcode/pre-market`, {headers: {Authorization: apiHeader()}})
};

const getSectorialView = () => {
    return axios.get(`${API_BASE_URL}/tredcode/sectorial-view`, {headers: {Authorization: apiHeader()}})
};


const getIntradayStockDetail = (stockName, timeframe) => {
    return axios.get(`${API_BASE_URL}/tredcode/stock/` + stockName + '?tf=' + timeframe, {headers: {Authorization: apiHeader()}})
};
const getSwingCenterData = () => {
    return axios.get(`${API_BASE_URL}/tredcode/swing-center`, {headers: {Authorization: apiHeader()}})
};


export default {
    getMomentumStocks,
    getIntradayBoostStocks,
    getSectorialView,
    getHighPowerStocks,
    getPremarketMovers,
    getIntradayStockDetail,
    getSwingCenterData
}