import axios from "axios";
import {API_BASE_URL} from "../../constants/UrlServiceConstants";
import {apiHeader} from "./headers/ApiHeaders";
import DateHelper from "../../helper/DateHelper";

const getTradeAuthInfo = () => {
    const currentTimeMs = DateHelper.removeTimeFromDate(new Date())
    localStorage.setItem("kite-auth-time", JSON.stringify(currentTimeMs.getTime()))
    return axios.get(`${API_BASE_URL}/zerodha/trading-auth-info`, {headers: {Authorization: apiHeader()}})
};

const getKiteAuthDetails = () => {
    const currentTimeMs = new Date().getTime();
    localStorage.setItem("kite-auth-time", JSON.stringify(currentTimeMs))
    return axios.get(`${API_BASE_URL}/oauth/zerodha/auth-details`, {headers: {Authorization: apiHeader()}})
};

const getKiteAuthUrl = () => {
    return axios.get(`${API_BASE_URL}/oauth/zerodha/initialize`, {headers: {Authorization: apiHeader()}})
};

const createSession = (reqToken) => {
    return axios.get(`${API_BASE_URL}/oauth/zerodha/auth-token?kiteRequestToken=${reqToken}`, {headers: {Authorization: apiHeader()}})
};


const disconnectKite = () => {
    return axios.put(`${API_BASE_URL}/oauth/zerodha/disconnect`, {}, {headers: {Authorization: apiHeader()}})
};


export default {getKiteAuthDetails, getKiteAuthUrl, createSession, disconnectKite, getTradeAuthInfo};