const FILE_STARTS_WITH = "fo";
const FILE_ENDS_WITH = "bhav";

function validateFile(file) {
    const fileInfo = file[0];
    let isOK = false;
    if (fileInfo.type === "application/vnd.ms-excel"){
        isOK = true;
    }

    // fo06JUL2021bhav
    const fileNameWithExtn = fileInfo.name;
    const fileName = fileNameWithExtn.split('.')[0];

    if (!fileName.startsWith('fo')){
        isOK = false;
        return isOK;
    }

    if (!fileName.endsWith('bhav')){
        isOK = false;
        return isOK;
    }

    // TODO: Pattern matching
    const dateSubStr = fileName.substring(2, 11);
    return isOK
}

export default {validateFile}
