import React from 'react'
import {Button} from "devextreme-react/button";
import {Link} from "react-router-dom";

const KiteLoginComponent = (props) => {
    return (
        <div className={"kit-login"}>
            <div>
                {/*<img src={'https://kite.zerodha.com/static/images/kite-logo.svg'}/><br/>*/}
                {!props.hasKiteApi && (
                    <div>
                        <span style={{color: "#ff4d4d"}}>Kite API Details not found</span><br/>
                        Please configure your Zerodha Kite API details to continue {' '}
                        <Link to={"/preferences"}>
                            <button className={"cust-btn btn-default"}>Add Now</button>
                        </Link><br/><br/>
                    </div>
                )}
                <Button
                    icon={'https://kite.zerodha.com/static/images/kite-logo.svg'}
                    text={'Connect Kite'}
                    width={'auto'}
                    onClick={props.login}
                    disabled={!props.hasKiteApi}
                >
                </Button>
            </div>
        </div>
    )
}
export default KiteLoginComponent;