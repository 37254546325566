function getEntryTimeArrayRange() {
    return ["09:20",
        "09:24",
        "09:25",
        "09:26",
        "09:27",
        "09:28",
        "09:29",
        "09:30",
        "09:31",
        "09:32",
        "09:33",
        "09:34",
        "09:35",
        "09:40",
        "09:41",
        "09:42",
        "09:43",
        "09:44",
        "09:45",
        "09:46",
        "09:47",
        "09:48",
        "09:49",
        "09:50",
        "09:51",
        "09:52",
        "09:53",
        "09:54",
        "09:55",
        "09:56",
        "09:57",
        "09:58",
        "09:59",
        "10:00",
        "10:05",
        "10:10",
        "10:15",
        "10:20",
        "10:25",
        "10:30",
        "10:35",
        "10:40",
        "10:45",
        "10:50",
        "10:55",
        "11:00"
    ];
}

function getExitTimeArrayRange() {
    return ["14:00",
        "14:05",
        "14:10",
        "14:15",
        "14:20",
        "14:25",
        "14:30",
        "14:35",
        "14:40",
        "14:45",
        "14:46",
        "14:47",
        "14:48",
        "14:49",
        "14:50",
        "14:51",
        "14:52",
        "14:53",
        "14:54",
        "14:55",
        "14:56",
        "14:57",
        "14:58",
        "14:59",
        "15:00",
        "15:01",
        "15:05",
        "15:10",
        "15:15",
        "15:16",
        "15:17",
        "15:18",
        "15:19",
        "15:20"];
}

const rangeGenerator = (start, stop, step = 1) => {
    return Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => {
        return (x + y * step);
    })
}

const rangeGeneratorWithRound = (start, stop, step = 1) => {
    return Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => {
        return Math.round((x + y * step) * 100) / 100;
    })
}

const entryTimeRange = getEntryTimeArrayRange();
const exitTimeRange = getExitTimeArrayRange();
const maxRiskPrice = rangeGenerator(1000, 101000, 500);
const stopLossThreshold = rangeGenerator(0.00, 5.25, 0.25);
const stopLossPointsRange = rangeGenerator(0, 510, 10);
const maxMarginPerStock = rangeGenerator(0, 55000, 5000);
const entryPriceRange = rangeGeneratorWithRound(0.05, 1.01, 0.01);
const waitAndTradePointsRange = rangeGeneratorWithRound(0, 310, 10);

export default {
    entryTimeRange,
    exitTimeRange,
    maxRiskPrice,
    stopLossThreshold,
    stopLossPointsRange,
    maxMarginPerStock,
    entryPriceRange,
    waitAndTradePointsRange
};