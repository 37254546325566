import React, {useEffect, useState} from 'react';
import "./auto-trade.scss"
import KiteAuthService from "../../api/service/KiteAuthService";
import Notification from '../../utils/notification-toast'
import {Toaster} from "react-hot-toast";
import {KITE_OAUTH_REDIRECT_URL, KITE_OAUTH_REDIRECT_URL_ERROR} from "../../constants/UrlServiceConstants";
import KiteLoginComponent from "./auth/KiteLoginComponent";
import Strategies from "./strategies/Strategies";
import BankNiftyIntradaySelling from "./strategies/bnis/BankNiftyIntradaySelling";
import BreakoutIntradayStock from "./strategies/bkis/BreakoutIntradayStock";
import DateHelper from "../../helper/DateHelper";
import {useAuth} from "../../contexts/auth";
import CustomStrategyComponent from "./CustomStrategyComponent";
import CustomStrategy from "./strategies/custom/CustomStrategy";

const AutoTradeMain = () => {

    // auth
    const {user} = useAuth();
    const [isKiteLogged, setIsKiteLogged] = useState(false);
    const [hasKiteApiInfo, setHasKiteApiInfo] = useState(false);
    const [strategy, setStrategy] = useState("");
    const [customStrategyInfo, setCustomStrategyInfo] = useState({strategyId: '', showCustomStrategy: false});
    const [kiteUserId, setKiteUserId] = useState("");
    const [kiteUserName, setKiteUserName] = useState("");
    const [kiteAuthInfo, setKiteAuthInfo] = useState({});

    // status
    const [loading, setLoading] = useState(false);
    const [tabIndexBKIS, setTabIndexBKIS] = useState(0);


    useEffect(() => {
        // fetchKiteAuthDetails()
        if (user) {
            getTradeAuthInfo()
        }

        // interval for checking kite login
        // calls auth check API if date's are not same
        setInterval(() => {
            let lastKiteAuthDetailFetchTime = JSON.parse(localStorage.getItem("kite-auth-time"));
            const currentTimeMs = DateHelper.removeTimeFromDate(new Date()).getTime()
            if (lastKiteAuthDetailFetchTime !== currentTimeMs) {
                getTradeAuthInfo()
            }
        }, 300000)
        return () => {

        }
    }, []);


    function showKiteLoginMsg() {
        Notification.warning("Please connect your Kite account.")
    }

    function getTradeAuthInfo() {
        setLoading(true)
        KiteAuthService.getTradeAuthInfo()
            .then(resp => {
                if (resp.status === 200) {
                    const respData = resp.data;
                    setHasKiteApiInfo(respData.kiteApiInfo.hasApiInfo)
                    setIsKiteLogged(respData.authDetails.hasAccessToken)
                    if (respData.authDetails.hasAccessToken === true) {
                        setKiteUserId(respData.authDetails.userInfo.kiteUserId);
                        setKiteUserName(respData.authDetails.userInfo.userName);
                    }

                    if (respData.authDetails.hasAccessToken === true && respData.kiteApiInfo.hasApiInfo === true) {
                        setKiteAuthInfo({
                            accessToken: respData.authDetails.userInfo.accessToken,
                            apiKey: respData.authDetails.userInfo.apiKey
                        })
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                Notification.error("something went wrong!")
                console.log(err)
            })
    }

    function fetchKiteAuthDetails() {
        setLoading(true)
        KiteAuthService.getKiteAuthDetails().then(resp => {
            setLoading(false)
            if (resp.status === 200) {
                setHasKiteApiInfo(resp.data.hasApiInfo)
                setIsKiteLogged(resp.data.hasAccessToken)
                setKiteUserId(resp.data.userInfo.kiteUserId);
                setKiteUserName(resp.data.userInfo.userName);
                if (resp.data.hasAccessToken === true && resp.data.hasApiInfo === true) {
                    setKiteAuthInfo({accessToken: resp.data.userInfo.accessToken, apiKey: resp.data.userInfo.apiKey})
                }
            } else {
                setIsKiteLogged(false)
                showKiteLoginMsg()
            }
        }).catch(err => {
            setLoading(false)
            showKiteLoginMsg()
        })
    }

    function loginWithKite() {
        setLoading(true)
        KiteAuthService.getKiteAuthUrl().then(resp => {
            setLoading(false)
            if (resp.status === 200) {
                openKiteLoginPopUp(resp.data)
            } else {
                showKiteLoginMsg()
            }
        }).catch(err => {
            setLoading(false)
            showKiteLoginMsg()
        })
    }

    function openKiteLoginPopUp(authUrl) {
        const kiteAuthPopUpWindow = window.open(authUrl, "", "width=500,height=500, left=500, top= 100");

        let pollingTimer = window.setInterval(async () => {
            try {
                const popUpWinUrl = kiteAuthPopUpWindow.document.URL;

                if (popUpWinUrl.startsWith(KITE_OAUTH_REDIRECT_URL)) {
                    window.clearInterval(pollingTimer);
                    const urlParams = new URL(popUpWinUrl);
                    const kiteAuthStatus = urlParams.searchParams.get("status");
                    const oauthReqToken = urlParams.searchParams.get("request_token");

                    if (kiteAuthStatus === "success" && oauthReqToken !== "") {
                        await getRequestToken(oauthReqToken);
                        kiteAuthPopUpWindow.close();
                        window.location.reload()
                    }
                }

                if (popUpWinUrl.startsWith(`${KITE_OAUTH_REDIRECT_URL_ERROR}`)) {
                    window.clearInterval(pollingTimer);
                    kiteAuthPopUpWindow.close();
                    Notification.error("Unable to login!")
                }
            } catch (e) {
            }
        }, 100);
    }

    async function getRequestToken(reqToken) {
        setLoading(true)
        await KiteAuthService.createSession(reqToken).then(resp => {
            Notification.success("Login successful")
            setLoading(false)
            window.location.reload();
        }).catch(err => {
            setLoading(false)
            Notification.error(err)
        })
    }

    function setStrategyVal(val) {
        setStrategy(val)
    }

    function disconnectKite() {
        setLoading(true)
        KiteAuthService.disconnectKite().then(resp => {
            setLoading(false)
            if (resp.status === 200) {
                Notification.success("Kite disconnected successful.")
                window.location.reload();
            } else {
                Notification.error("Unable to disconnect")
            }
        }).catch(err => {
            setLoading(false)
            Notification.error("something went wrong!")
            console.log(err)
        });
    }

    function setCustomStrategy(id) {
        if (id === '' || id === undefined) {
            setStrategy('')
            setCustomStrategyInfo({strategyId: '', showCustomStrategy: false})
        } else {
            setCustomStrategyInfo({strategyId: id, showCustomStrategy: true})
        }
    }

    return (
        <div className={"container-at"}>
            {loading ? (
                <div>
                    <span>
                        <span>Loading <span className={'dot-loader'}></span></span>
                    </span>
                </div>) : (
                <div>
                    {user && (
                        <div>
                            <div className={"at-header"}>
                                {isKiteLogged && (
                                    <div>
                                        <span style={{fontSize: '15px'}}>Connected to Zerodha {' '}
                                            <span className={"font-lighter"}>(</span>
                                            {`${kiteUserId}`}<span className={"font-lighter"}>)</span>
                                        </span>{' '}
                                        <button className={"cust-btn btn-danger disconnect-btn"}
                                                onClick={() => disconnectKite()}>
                                            <span>
                                                <i className="dx-icon-clear"></i>
                                                Disconnect
                                            </span>
                                        </button>
                                    </div>
                                )}
                                {!isKiteLogged && (
                                    <div>No trading account connected</div>
                                )}
                            </div>
                            <div className={"at-body"}>
                                <div>

                                    {!isKiteLogged && (
                                        <>You need to connect your trading account to Trade Strategies. <br/><br/>
                                            Click on button below to connect your Zerodha Kite account<br/><br/>
                                        <KiteLoginComponent login={loginWithKite} hasKiteApi={hasKiteApiInfo}/>
                                        </>
                                    )}
                                </div>
                                {isKiteLogged && (
                                    <div>
                                        {/*{!customStrategyInfo.showCustomStrategy && (
                                            strategy === "" && (
                                                <Strategies
                                                    kiteUserName={"name"}
                                                    kiteUserId={123}
                                                    strategy={strategy}
                                                    setStrategy={setStrategyVal}
                                                />
                                            )
                                        )}*/}

                                        {/*{strategy === "BNIS" &&
                                        (<BankNiftyIntradaySelling setStrategy={setStrategyVal} tabIndex={tabIndexBKIS}
                                                                   setTabIndex={setTabIndexBKIS}/>)
                                        }*/}

                                        {customStrategyInfo.showCustomStrategy && (
                                            <CustomStrategy setStrategy={setCustomStrategy}
                                                            tabIndex={tabIndexBKIS}
                                                            setTabIndex={setTabIndexBKIS}
                                                            id={customStrategyInfo.strategyId}/>
                                        )}

                                        {!customStrategyInfo.showCustomStrategy && (
                                            <div>
                                                <CustomStrategyComponent setCustomStrategy={setCustomStrategy} setCus/>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {!user && (
                        <div>
                            <div style={{backgroundColor: '#e6ffe6'}}>
                                <h5>Auto Trade</h5>
                            </div>
                            <span>You need to log in to the application to start auto trading.</span>
                        </div>
                    )}
                </div>
            )
            }
            <Toaster/>
        </div>
    )
}

export default AutoTradeMain;