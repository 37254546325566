import {withNavigationWatcher} from './contexts/navigation';
import {HomePage} from './pages';
import OAuthRedirectPage from "./pages/oauth/oauth-loading";
import Preferences from "./pages/Preferences/Preferences";
import AutoTradeMain from "./pages/auto-trade/AutoTradeMain";
import OAuthKitePage from "./pages/auto-trade/auth/OAuthKitePage";
import IntradayStudyMain from "./pages/study/IntradayStudyMain";
import SwingStudyMain from "./pages/study/SwingStudyMain";
import AnalysisMain from "./pages/analysis/AnalysisMain";

const routes = [
    {
        path: '/preferences',
        component: Preferences
    },
    {
        path: '/home',
        component: HomePage
    },
    {
        path: `/oauth/code/response`,
        component: OAuthRedirectPage
    },
    {
        path: `/zerodha`,
        component: OAuthKitePage
    },
    {
        path: `/auto-trade`,
        component: AutoTradeMain
    },
    {
        path: `/intraday-study`,
        component: IntradayStudyMain
    },
    {
        path: `/swing-study`,
        component: SwingStudyMain
    },
    {
        path: `/analysis`,
        component: AnalysisMain
    }
];
export default routes.map(route => {
    return {
        ...route,
        component: withNavigationWatcher(route.component)
    };
});
