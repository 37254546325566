import React, {useEffect, useState} from 'react';
import "./analysis.scss"
import {useAuth} from "../../contexts/auth";
import TredCodeService from "../../api/service/TredCodeService";
import moment from 'moment';

const AnalysisMain = () => {
    const {user} = useAuth();

    const [symbol, setSymbol] = useState("NSE:NIFTY BANK");
    const [openPrice, setOpenPrice] = useState(45750.00);
    const [lastUpdated, setLastUpdated] = useState(moment());

    const [prevDayOhlc, setPrevDayOhlc] = useState({high: 45800, low: 45600, close: 45650})



    const autoRefreshMs = 10000; // 10 secs
    let interval;

    useEffect(() => {
        loadSymbols();
        /*interval = setInterval(() => {
            loadSwingCenterData();
        }, autoRefreshMs);*/
        return () => clearInterval(interval);
    }, []);

    function loadSymbols() {
        /*setIsStocksLoading(true);
        TredCodeService.getSwingCenterData()
            .then(response => {
                if (response.status === 200) {
                    setSectors(response.data.sectors);
                    setLongTermUpStocks(response.data.longTermUpStocks);
                    setLongTermDownStocks(response.data.longTermDownStocks);

                    setIsStocksLoading(false);
                    setLastUpdated(moment());
                }
            }).catch(error => {
            console.error(error)
        });*/
    }
/*
    function changeSelectedSector(sectorName) {
        setSelectedSector(sectorName);
    }

    function changeStockToHighlight(stockName) {
        setStockToHighlight(stockName);
    }

    function getHighlightStyleClass(stockName) {
        if (stockName === stockToHighlight) return " highlighted-stock";
        return "";
    }
    function getSwingTodaysDateClass(stockName, swingDate) {
        if(swingDate == null) return "";
        if (stockName === stockToHighlight) return "";
        var swingDateFormatted = moment(swingDate, timeformat).format('ll')
        var todayFormatted = moment().format('ll')
        if(todayFormatted === swingDateFormatted) return "swing-today";
        return "";
    }*/

    function getGreenOrRedColor(value) {
        // if value is negative, return red styled class,
        // if value is positive, return green styled class
        if (value >= 0) return " change-positive";
        else return " change-negative"
    }

    function isHigher(value1, value2) {
        // if value1 is higher than value 2
        if(value1>value2) return "Yes";
        if(value2>value1) return "No";
        return "-"
    }
    const timeformat = "yyyy-MM-DD";

    return (
        <div className={"container-studies"}>
            {user && (<>
                <div className={"study-header"}>
                    <div className={"row"}>
                        <div className={"col"}>{symbol}</div>
                        <div className={"col text-right"}>Last updated: {lastUpdated.format('llll')}</div>
                    </div>
                </div>
                <div className={"study-body"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <table width={"100%"}>
                                <tr>
                                    <td style={{width:'75%'}} valign={"top"}>
                                        <div className={"study-title study-sectors-view-title"}>Open Print Analysis</div>
                                        <div className={"study-section"}>

                                            <div className={"main-section-title"}>Open Print: <b>{openPrice}</b></div>

                                            <div className={"row"}>
                                                <div className={"col"}>
                                                    PDH: {prevDayOhlc.high}<br/>
                                                    PDL: {prevDayOhlc.low}<br/>
                                                    P.Close: {prevDayOhlc.close}<br/>
                                                </div>
                                                <div className={"col"}>
                                                </div>
                                                <div className={"col"}>
                                                    2-Day CPR<br/>
                                                    Higher-Value<br/>
                                                    Bullish
                                                </div>
                                                <div className={"col"}>
                                                    2-Day CAM3<br/>
                                                    Higher-Value<br/>
                                                    <span className={"volume-change change-positive"}> Bullish </span>
                                                </div>
                                            </div>

                                            <hr/>
                                            <div className={"row"}>
                                                <div className={"col"}>
                                                    {/*bullish side signals*/}
                                                    <table className={"study-table"}>
                                                        <tr>
                                                            <td>Above P.Close ?</td>
                                                            <td className={"text-center volume-change" + getGreenOrRedColor(openPrice - prevDayOhlc.close)}>
                                                                {isHigher(openPrice, prevDayOhlc.close)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Above PDH ?</td>
                                                            <td className={"text-center volume-change" + getGreenOrRedColor(openPrice - prevDayOhlc.high)}>
                                                                {isHigher(openPrice, prevDayOhlc.high)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Above CPR ?</td>
                                                            <td>Yes</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Above CAM3 ?</td>
                                                            <td>Yes</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div className={"col"}>
                                                    {/*bearish side signals*/}
                                                    <table className={"study-table"}>
                                                        <tr>
                                                            <td>Below P.Close ?</td>
                                                            <td className={"text-center volume-change" + getGreenOrRedColor(prevDayOhlc.close - openPrice)}>
                                                                {isHigher(prevDayOhlc.close, openPrice)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Below PDL ?</td>
                                                            <td className={"text-center volume-change" + getGreenOrRedColor(prevDayOhlc.low - openPrice)}>
                                                                {isHigher( prevDayOhlc.low,openPrice)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Below CPR ?</td>
                                                            <td>No</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Below CAM3 ?</td>
                                                            <td>No</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div className={"col"}>
                                                    CPR Width:<br/>
                                                    Wide / Narrow / Avg
                                                </div>
                                                <div className={"col"}>
                                                    CAM3 Width:<br/>
                                                    Wide / Narrow / Avg
                                                </div>
                                            </div>
                                            <br/>
                                            Bias: <div className={"text-center volume-change change-positive"}>&nbsp;&nbsp; BULLISH -- 100%</div>
                                            <br/>
                                            Plan:
                                            <br/>
                                            1. Buy any Pullback to CPR, S1 or L3 (Cam)<br/>
                                            2. Buy on bullish candle in 5-min or 15-min TF.
                                        </div>
                                    </td>
                                    <td style={{width:'25%'}} valign={"top"}>
                                        <div className={"study-title study-stock-info2-title"}>Quick Trade</div>
                                        <div className={"study-section"}>
                                            quick action options to initiate trade and setup SLs.


                                            <br/><br/><br/><br/><br/><br/><br/><br/>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    {/*Pivot points*/}
                    <div className={"row"}>
                        <div className={"col"}>
                            <div className={"study-title study-momentum-title"}>Pivot Points</div>
                            <div className={"study-section"}>
                                <table>
                                    <tr><td>data here</td></tr>
                                </table>
                            </div>
                        </div>

                        <div className={"col"}>
                            <div className={"study-title study-intraday-boost-title"}>Camarilla Points</div>
                            <div className={"study-section"}>
                                <table>
                                    <tr><td>data here</td></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </>)}


            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    )
}

export default AnalysisMain;