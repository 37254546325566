import React, {useEffect, useState} from 'react';
import './preferences.scss';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AnalysisThresholdHelper from "../../helper/AnalysisThresholdHelper";
import PreferencesService from "../../api/service/UserPreferencesService";
import Notification from "../../utils/notification-toast"
import {useHistory} from "react-router";
import KiteApiInfoService from "../../api/service/KiteApiInfoService";
import {Toaster} from "react-hot-toast";

const initState = {
    priceThreshold: 0/*MetaDataService.getOIThresholdValues().priceThresholdPercent*/,
    oiThreshold: 0/*MetaDataService.getOIThresholdValues().oiThresholdPercent*/
};

export default function () {
    const history = useHistory();
    const oiThRange = AnalysisThresholdHelper.oiThRange;
    const closePriceThRange = AnalysisThresholdHelper.closePriceThRange;

    // input field
    const [editable, setEditable] = useState(false);

    const [priceThreshold, setPriceThreshold] = useState(initState.priceThreshold);
    const [oiThreshold, setOiThreshold] = useState(initState.oiThreshold);

    const [kiteApiInfo, setKiteApiInfo] = useState({});
    const [apiKey, setApiKey] = useState("");
    const [apiSecret, setApiSecret] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // loadPreferences()
        fetchKiteApiInfo()
    }, []);

    const loadPreferences = () => {
        setLoading(true)
        PreferencesService?.loadPrefs().then(resp => {
            setPriceThreshold(resp.data.pricePercentThreshold);
            setOiThreshold(resp.data.oiPercentThreshold)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            console.log(err)
        });
    };

    const fetchKiteApiInfo = () => {
        setLoading(true)
        KiteApiInfoService.getKiteApiInfo().then(resp => {
            if (resp.status === 200) {
                setKiteApiInfo(resp.data)
                setApiKey(resp.data.apiKey)
                setApiSecret(resp.data.apiSecret)
                setEditable(true)
            } else {
                setEditable(false)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            setEditable(false)
            console.log(err)
        })
    }

    const saveKiteApiInfo = () => {
        if (apiKey !== '' && apiSecret !== '') {
            setLoading(true)
            setEditable(true)
            KiteApiInfoService.saveKiteApiInfo({apiSecret, apiKey}).then(resp => {
                Notification.success("Saved.")
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                console.log(err)
                Notification.error("Unable to save")
            });
        } else {
            Notification.error("Please fill the fields!")
        }
    }

    const savePreferences = () => {
        const apiBody = {pricePercentThreshold: priceThreshold, oiPercentThreshold: oiThreshold};
        PreferencesService.updatePrefs(apiBody)
            .then(resp => {
                Notification.success("Preferences updated successfully.")
            })
            .catch(err => {
                Notification.error("Unable to update preferences.")
            });
    };

    return (
        <>
            <div className={"preference"}>
                <Tabs>
                    <TabList>
                        <Tab><span><i className="dx-icon-smalliconslayout"></i> Kite API</span></Tab>
                    </TabList>

                    <TabPanel>
                        <div className={"tab-content"}>
                            <div>
                                <strong>Configure your Kite App's API Key and API Secret. Get it from Kite Trade
                                    (https://developers.kite.trade/) </strong>
                            </div>
                            <div>
                                <table style={{width: '50%'}} className={"cust-bg-light p-10"}>
                                    <tr>
                                        <td><label htmlFor={"apiKey"}>API Key</label></td>
                                        <td>
                                            {loading && (
                                                <div>
                                                    <span>Loading <span className={'dot-loader'}></span></span>
                                                </div>
                                            )}
                                            {!loading && (
                                                <input type={"text"}
                                                       autoComplete={"off"}
                                                       id={"apiKey"}
                                                       value={apiKey}
                                                       style={{width: '100%'}}
                                                       placeholder={"Enter API Key"}
                                                       onChange={(event => setApiKey(event.target.value))}
                                                       disabled={editable}/>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><label htmlFor={"apiSecret"}>API Secret</label></td>
                                        <td>
                                            {!loading && (
                                                <input
                                                    type={"text"}
                                                    autoComplete={"off"}
                                                    id={"apiSecret"}
                                                    value={apiSecret}
                                                    placeholder={"Enter API Secret"}
                                                    style={{width: '100%'}}
                                                    onChange={(event => setApiSecret(event.target.value))}
                                                    disabled={editable}/>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                                <span><br/>

                                                    {editable && (
                                                        <button className={"cust-btn btn-success"}
                                                                style={{fontSize: '15px'}}
                                                                onClick={() => setEditable(false)}>
                                                            <i className="dx-icon-edit"></i>{' '}
                                                            Edit
                                                        </button>
                                                    )}

                                                    {!editable && (
                                                        <button className={"cust-btn btn-primary"}
                                                                style={{fontSize: '15px'}}
                                                                onClick={() => saveKiteApiInfo()}>
                                                            <i className="dx-icon-check"></i>{' '}
                                                            Save
                                                        </button>
                                                    )}
                                                    {' '}
                                                    <button className={"cust-btn btn-default"}
                                                            style={{fontSize: '15px'}}
                                                            onClick={() => history.goBack()}>
                                                &lt; Back
                                                </button>

                                                    {/*// todo: Delete api info*/}
                                                </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>

            </div>
            <Toaster/>
        </>
    );
}
