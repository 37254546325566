const getArrayRangeWithPercent = (start, stop, step = 1) =>
    Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => {
        return {name: `${x + y * step} %`, value: `${x + y * step}`}
    });


const oiThRange = getArrayRangeWithPercent(5, 20.5, 0.5);
const closePriceThRange = getArrayRangeWithPercent(0.5, 10.5, 0.5);

export default {oiThRange, closePriceThRange}
