import axios from "axios";
import jwt from 'jwt-decode'
import {API_BASE_URL} from "../../constants/UrlServiceConstants";

const authGoogle = () => {
    return axios.get(`${API_BASE_URL}/oauth/google/initialize`)
};

const authToken = (googleOAuthCode) => {
    const bodyData = {code: googleOAuthCode};
    return axios.post(`${API_BASE_URL}/oauth/google/auth-token`, bodyData,
        {headers: {"Access-Control-Allow-Origin": "http://localhost:8080"}})
};

const saveAuthInfo = (userAuthInfo) => {
    localStorage.setItem("auth-info", JSON.stringify(userAuthInfo));
};

const getAuthInfo = () => {
    return JSON.parse(localStorage.getItem("auth-info"))
};

const getAccessToken = () => {
    const accessToken = getAuthInfo();
    if (accessToken === null){
        return null;
    }
    return accessToken?.accessToken;
};

const getUserInfo = () => {
    const accessToken = getAccessToken();
    if (!accessToken) {
        return null;
    }
    const userInfo = jwt(accessToken);
    return Object.keys(userInfo).length !== 0 ? userInfo : null;
};

const logout = () => {
    localStorage.removeItem('auth-info')
};

export default {
    authGoogle,
    authToken,
    saveAuthInfo,
    getAccessToken,
    getUserInfo,
    logout
};
