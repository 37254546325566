import UserAuthService from "../api/service/UserAuthService";

function userInfo() {
    const userInfo = UserAuthService.getUserInfo();
    if (userInfo !== null){
        return {name: userInfo.name, profileImgUrl: userInfo.profileImgUrl, role: userInfo.role};
    }
    return null;
}

export default {
    name: userInfo() !== null ? userInfo().name : null,
    avatarUrl: userInfo()  !== null ? userInfo().profileImgUrl : null,
    role: userInfo()  !== null ? userInfo().role : null
}
