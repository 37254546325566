import React, {useEffect, useState} from 'react'
import Notification from "../../../../utils/notification-toast"
import DateHelper from "../../../../helper/DateHelper";
import CustomStrategyService from "../../../../api/service/CustomStrategyService";

const CustomTradeInfoComp = (props) => {
    // status
    const [loading, setLoading] = useState(false);

    const [tradeInfo, setTradeInfo] = useState({});
    const [ceOptionInfo, setCeOptionInfo] = useState({})
    const [peOptionInfo, setPeOptionInfo] = useState({})

    let interval;
    useEffect(() => {
        fetchTradingInfo();
        interval = setInterval(() => {
            fetchTradingInfo()
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    function fetchTradingInfo() {
        if (props.strategyConfigId != null) {
            setLoading(true)
            CustomStrategyService.fetchStrategyNewTradeInfo(props.strategyConfigId).then(resp => {
                    setLoading(false)
                    if (resp.status === 200) {
                        setTradeInfo(resp.data)
                        setCeOptionInfo(resp.data.ceOption)
                        setPeOptionInfo(resp.data.peOption)
                        //Notification.success("Trade Info Fetched successfully")
                    } else {
                        Notification.error("Error: Unable to fetch trade info!")
                    }
                }
            ).catch(err => {
                setLoading(false)
                Notification.error("Error: " + err)
            })
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    function pivotColorCode(isNear, isNearer, isAtPivot) {
        if (isAtPivot) {
            return {backgroundColor: '#ffff00'};
        }
        if (isNearer) {
            return {backgroundColor: '#ffff80'};
        }
        if (isNear) {
            return {backgroundColor: '#ffffaa'};
        }
    }

    function findDifference(from, to){
        if(from == null || to == null) return 0;
        let diff = from - to;
        diff = Math.round(diff * 100) / 100;
        return diff;
    }

    return (
        <>
            <div className={'tab-content'}>
                <div>
                    <table className={"pivot-table"} style={{backgroundColor: 'white'}}>
                        <tr>
                            <td><small>
                                <span className={"label-yellow"}>{tradeInfo?.strategyConfig?.instrument}</span>
                                &nbsp;&nbsp;{tradeInfo?.strategyConfig?.transactionType === 'BUY' &&
                                <span className={"label-blue"}>Buy</span>}
                                {tradeInfo?.strategyConfig?.transactionType === 'SELL' &&
                                    <span className={"label-red"}>Sell</span>}
                                &nbsp;&nbsp;{tradeInfo?.strategyConfigEntity?.automatic === true && tradeInfo?.strategyConfig?.onExpiryDayOnly === true &&
                                <span className={"label-purple"}>Expiry Only</span>}

                                <span className={"label-pink"}>{tradeInfo?.strategyConfig?.bias}</span>
                            </small>
                            </td>
                            <td>
                                <div style={{float: 'right'}}>
                                    {loading && <span style={{fontWeight: 'lighter'}}>&nbsp;<span
                                        className={'dot-loader'}></span></span>}
                                    <button className={"cust-btn label-lightblue"} onClick={() => fetchTradingInfo()}><i
                                        className="dx-icon-refresh"></i> Refresh
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><small style={{float: 'right'}}>
                                <i>{tradeInfo.timestamp && DateHelper.formatDateTime(tradeInfo?.timestamp)}</i>
                            </small>
                            </td>
                        </tr>
                    </table>

                    <table className={"pivot-table"}>
                        <tr>
                            <td>Spot: <b>{tradeInfo?.spotPrice}</b></td>
                            <td>Fut: <b>{tradeInfo?.futurePrice}</b></td>
                            <td>Synth Fut: <b>{ Math.round(tradeInfo?.syntheticFuturePrice * 100) / 100}</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>{findDifference(tradeInfo?.futurePrice, tradeInfo?.spotPrice) > 0 ?
                                <span className={"label-yellow"}>{findDifference(tradeInfo?.futurePrice, tradeInfo?.spotPrice) }</span>:
                                <span className={"label-red"}>{findDifference(tradeInfo?.futurePrice, tradeInfo?.spotPrice) }</span>}</td>
                            <td>{findDifference(tradeInfo?.syntheticFuturePrice, tradeInfo?.spotPrice) > 0 ?
                                <span className={"label-yellow"}>{findDifference(tradeInfo?.syntheticFuturePrice, tradeInfo?.spotPrice)}</span>:
                                <span className={"label-red"}>{findDifference(tradeInfo?.syntheticFuturePrice, tradeInfo?.spotPrice)}</span>}</td>
                        </tr>
                    </table>

                    <table className={"pivot-table"} style={{backgroundColor: 'white'}}>
                        <tr>
                            <td>
                                <small>
                                    W&T: {tradeInfo?.strategyConfig?.waitAndTradePercent}%, &nbsp;
                                    SL: {tradeInfo?.strategyConfig?.stopLossPercent}%
                                </small>
                            </td>
                            <td>
                                <small
                                    style={{float: 'right'}}>Expiry: {tradeInfo.expiryDate} &nbsp; </small>
                            </td>
                        </tr>
                    </table>
                </div>
                {/*<div>
                    <table className={"pivot-table"}>
                        <tr>
                            <td style={{width: '50%', verticalAlign: 'top', paddingRight: '5px'}}>
                                <b>Pivots</b>
                                <table style={{width: '100%', backgroundColor: '#f0faff', cellPadding: '0px'}}>
                                    {(tradeInfo.pivots != null && tradeInfo.pivots.length !== 0) ?
                                        tradeInfo.pivots?.map((item, index) =>
                                            (
                                                <tr key={index}
                                                    style={pivotColorCode(item.isNear, item.isNearer, item.isAtPivot)}>
                                                    <td> {item.pivotType} &nbsp;</td>
                                                    <td align={"right"}>{item.pivotPrice} &nbsp;</td>
                                                    <td align={"right"}><b>{item.spotToPivotDifference}</b></td>
                                                </tr>
                                            )
                                        ) : <span><i> --- </i></span>
                                    }
                                </table>
                            </td>
                            <td align={"right"} style={{width: '50%', verticalAlign: 'top', paddingLeft: '5px', align: 'right'}}>
                                Camarilla &nbsp;
                                <table style={{width: '100%', backgroundColor: 'rgba(237,218,241,0.92)', cellPadding: '0px'}}>
                                    {(tradeInfo.camarillaPoints != null && tradeInfo.camarillaPoints.length !== 0) ?
                                        tradeInfo.camarillaPoints?.map((item, index) =>
                                            (
                                                <tr key={index}
                                                    style={pivotColorCode(item.isNear, item.isNearer, item.isAtPivot)}>
                                                    <td> {item.levelType} &nbsp;</td>
                                                    <td align={"right"}>{item.levelPrice} &nbsp;</td>
                                                    <td align={"right"}><b>{item.spotToLevelDifference}</b></td>
                                                </tr>
                                            )
                                        ) : <span><i> --- </i></span>
                                    }
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>*/}

                <div className={"row"} style={{paddingTop: '7px'}}>
                    <table className={"table-tb"}>
                        <tr style={{backgroundColor: '#e6e6e6'}}>
                            <th className={"text-left"}>ATM: {tradeInfo.atmStrike} {/*&nbsp;<small>({tradeInfo.isMonthlyExpiry ? "Fut" : "Spot"})</small>*/}</th>
                            <th className={"text-right"}>Premium</th>
                            <th className={"text-right"}>Entry</th>
                            <th className={"text-right"}>SL</th>
                            {/*<th className={"text-right"}>Target</th>*/}
                        </tr>
                        <tr>
                            <td className={""} data-th={'Trade'}>
                                {/*<i className="dx-icon-check" style={{color:"green"}}></i>&nbsp;*/}
                                {ceOptionInfo.transactionType} CE
                                : {ceOptionInfo.strike}</td>
                            <td className={"text-right"} data-th={'Premium'}>{ceOptionInfo.premium}</td>
                            <td className={"text-right"} data-th={'Entry Price'}>
                                <strong>{ceOptionInfo.entryPrice && formatter.format(ceOptionInfo.entryPrice)}</strong>
                            </td>
                            <td className={"text-right"} data-th={'stop loss'}>
                                <strong>{ceOptionInfo.stopLossPrice && formatter.format(ceOptionInfo.stopLossPrice)}</strong>
                            </td>
                            {/*<td className={"text-right"} data-th={'target'}>
                                <i>{ceOptionInfo.targetPrice && formatter.format(ceOptionInfo.targetPrice)}</i>
                            </td>*/}
                        </tr>
                        <tr>
                            <td className={""} data-th={'Trade'}>
                                {/*<i className="dx-icon-remove" style={{color:"red"}}></i>&nbsp;*/}
                                {ceOptionInfo.transactionType} PE
                                : {peOptionInfo.strike}</td>
                            <td className={"text-right"} data-th={'Premium'}>{peOptionInfo.premium}</td>
                            <td className={"text-right"} data-th={'Entry Price'}>
                                <strong>{peOptionInfo.entryPrice && formatter.format(peOptionInfo.entryPrice)}</strong>
                            </td>
                            <td className={"text-right"} data-th={'stop loss'}>
                                <strong>{peOptionInfo.stopLossPrice && formatter.format(peOptionInfo.stopLossPrice)}</strong>
                            </td>
                            <td className={"text-right"} data-th={'target'}>
                                <i>{peOptionInfo.targetPrice && formatter.format(peOptionInfo.targetPrice)}</i>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style={{paddingTop: '3px'}}>
                    <table className={"risk-loss-table"} style={{}}>
                        <tr>
                            <td>Max Risk</td>
                            <td>{tradeInfo.maxRiskAmount && formatter.format(tradeInfo.maxRiskAmount)}</td>
                        </tr>
                        <tr>
                            <td style={{width: '140px'}}>Max Loss</td>
                            <td>
                                <b>{tradeInfo.maxLoss && formatter.format(tradeInfo.maxLoss)}</b>
                                &nbsp;&nbsp;&nbsp; {tradeInfo.maxLossPerLot && `[${tradeInfo.maxLossPerLot && formatter.format(tradeInfo.maxLossPerLot)
                            } / lot]`}
                            </td>
                        </tr>
                        <tr>
                            <td>Max Lots</td>
                            <td>
                                <b>{tradeInfo?.maxLotsToTrade}</b>  &nbsp;&nbsp;&nbsp;{tradeInfo.maxQtyToTrade && `[ ${tradeInfo.maxQtyToTrade} ]`}
                            </td>
                        </tr>
                    </table>
                </div>

            </div>
        </>
    )
}

export default CustomTradeInfoComp;