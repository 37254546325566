import React from 'react';
import {Popup} from "devextreme-react/popup";
import GoogleLogin from "../google-login";

const LoginPopUp = ({loginPopupVisible, closeLoginPopup}) => {

    return (
        <>
            <Popup
                width={450}
                height={500}
                visible={loginPopupVisible}
                title={"Login"}
                showCloseButton={true}
                onHiding={closeLoginPopup} >
                <GoogleLogin/>
            </Popup>
        </>
    )
};

export default LoginPopUp;
