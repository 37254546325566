import React from 'react';
import {Button} from "devextreme-react/button";
import UserAuthService from "../../api/service/UserAuthService";
import NotificationToast from "../../utils/notification-toast"
import {OAUTH2_REDIRECT_URL, OAUTH2_REDIRECT_URL_ERROR} from "../../constants/UrlServiceConstants";
import {Form, GroupItem} from "devextreme-react/form";

export default function () {

    function onGoogleLoginClick(e) {
        UserAuthService.authGoogle().then(resp => {
            if (resp.data !== "") {
                openPopUp(resp.data)
            }
        }).catch(err => {
            NotificationToast.error("Not able to login with Google")
        })
    }

    function openPopUp(oauthUri) {
        const authPopUpWindow = window.open(oauthUri, "", "width=500,height=500, left=500, top= 100");

        let pollingTimer = window.setInterval(async () => {
            try {
                const popUpWinUrl = authPopUpWindow.document.URL;
                if (popUpWinUrl.startsWith(OAUTH2_REDIRECT_URL)) {
                    window.clearInterval(pollingTimer);
                    const urlParams = new URL(popUpWinUrl);
                    const googleOAuthCode = urlParams.searchParams.get("code");
                    const oauthState = urlParams.searchParams.get("state");
                    if (googleOAuthCode !== "" && oauthState === "trade_helper_state") {
                        await getAccessToken(googleOAuthCode);
                        authPopUpWindow.close();
                        window.location.reload()
                    }
                }

                if (popUpWinUrl.startsWith(`${OAUTH2_REDIRECT_URL_ERROR}`)) {
                    window.clearInterval(pollingTimer);
                    authPopUpWindow.close();
                    NotificationToast.error("Unable to login!")
                }
            } catch (e) {
            }
        }, 100);
    }

    async function getAccessToken(googleOAuthCode) {
        await UserAuthService.authToken(googleOAuthCode).then(resp => {
            UserAuthService.saveAuthInfo(resp.data)
        }).catch(err => {
            NotificationToast.error(err)
        })
    }

    return (
        <>

            <Form>
                <GroupItem>
                    <div className={"login-header"}>
                        <span>
                            By logging in, you will be able to manage your Trading Journal and mark stocks are favorites for
                            quick finding your stocks.
                        </span>
                        <br/><br/>
                    </div>
                </GroupItem>
                <GroupItem>
                    <GroupItem>
                        <div className={"login-body"}>
                            <Button
                                icon={'https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA'}
                                text={'Login With Google'}
                                width={'auto'}
                                onClick={onGoogleLoginClick}>
                            </Button>
                        </div>
                    </GroupItem>
                </GroupItem>
                <GroupItem cssClass={"login-footer"}>
                
                    <div className={"login-footer"}>
                        <br/><br/><br/><br/><br/><br/><br/><br/>
                        <span>
                            <strong>Disclaimer: </strong>
                             Only basic profile details will be used for communication purposes.
                         </span>
                    </div>
                </GroupItem>
            </Form>

        </>
    )
};
